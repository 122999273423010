import React from "react";
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from "react-router-dom";

import { useAccount } from "contexts/accountContext";
import Hubspot from "services/http/Hubspot";

interface InformationNavigationInterface {
    keyName?: string,
    removeClasses?: boolean,
    email?: string | null,
    onRetry?: () => void,
    onContactUs?: () => void
}

interface FormsIdInterface {
    [key: string]: string | undefined
}

const UPGRADE_BUTTON = ["index-upgrade", "calculator", "cei", "smart-label-upgrade", "integration-upgrade", "plm-upgrade", "lca-upgrade", "data-collection-upgrade", "library-upgrade"]

const WAIT_LIST_FORM_ID_BY_KEY_NAME: FormsIdInterface = {
    "data-collection": process.env.REACT_APP_HUBSPOT_WAITLIST_FORM_ID_DATA_COLLECTION,
    "plm": process.env.REACT_APP_HUBSPOT_WAITLIST_FORM_ID_PLM,
    "journey-map": process.env.REACT_APP_HUBSPOT_WAITLIST_FORM_ID_JOURNEY_MAP,
    "academy": process.env.REACT_APP_HUBSPOT_WAITLIST_FORM_ID_ACADEMY,
    "glossary": process.env.REACT_APP_HUBSPOT_WAITLIST_FORM_ID_GLOSSARY,
    "reporting": process.env.REACT_APP_HUBSPOT_WAITLIST_FORM_ID_REPORTING
}

const UPGRADE_FORM_ID_BY_KEY_NAME: FormsIdInterface = {
    "index-upgrade": process.env.REACT_APP_HUBSPOT_UPGRADE_FORM_ID_ECO_SCORE,
    "calculator": process.env.REACT_APP_HUBSPOT_UPGRADE_FORM_ID_CALCULATOR,
    "cei": process.env.REACT_APP_HUBSPOT_WAITLIST_FORM_ID_CEI,
    "smart-label-upgrade": process.env.REACT_APP_HUBSPOT_UPGRADE_FORM_ID_SMART_LABEL,
    "integration-upgrade": process.env.REACT_APP_HUBSPOT_UPGRADE_FORM_ID_INTEGRATION,
    "plm-upgrade": process.env.REACT_APP_HUBSPOT_UPGRADE_FORM_ID_PLM,
    "lca-upgrade": process.env.REACT_APP_HUBSPOT_UPGRADE_FORM_ID_LCA,
    "data-collection-upgrade": process.env.REACT_APP_HUBSPOT_UPGRADE_FORM_ID_DATA_COLLECTION,
    "library-upgrade": process.env.REACT_APP_HUBSPOT_UPGRADE_FORM_ID_LIBRARY
}

const BLOCK_LIST = ["cei-block"]

const IMAGE_GIF = [
    "data-collection", "journey-map", "academy", "reporting", "glossary", "plm",
    "smart-label", "index", "index-upgrade", "calculator", "cei", "cei-empty",
    "cei-block", "lca-product", "smart-label-upgrade", "integration-upgrade",
    "plm-upgrade", "lca-upgrade", "data-collection-upgrade", "library-upgrade"
]
const WAIT_LIST_BUTTON = ["data-collection", "plm", "journey-map", "academy", "glossary", "reporting"]

export const InformationNavigation = (props: InformationNavigationInterface) => {
    const h = useHistory();
    const location = useLocation();
    const { user } = useAccount();
    const { keyName, removeClasses, email, onRetry, onContactUs } = props;
    const { t } = useTranslation();

    const sendMail = () => {
        if (email) {
            window.location.href = `mailto:${email}`
        } else {
            window.open('https://bcome.biz/contact/', '_blank');
        }
    }

    const gifImage = keyName && IMAGE_GIF.includes(keyName);
    const imagePath = keyName ? `../img/${gifImage ? "gifs/" : ""}${keyName}-scheme.${gifImage ? "gif" : "png"}` : '';

    const handleSubmitWaitList = (e: any) => {
        e.preventDefault();
        if (keyName) {
            const formId = WAIT_LIST_FORM_ID_BY_KEY_NAME[keyName];
            if (formId) {
                new Hubspot().submit(user?.email || '', t("wait-list-notification-success"), t("wait-list-notification-error"), formId);
            }
        }
    }

    const handleSubmitUpgrade = (e: any) => {
        e.preventDefault();
        if (keyName) {
            const formId = UPGRADE_FORM_ID_BY_KEY_NAME[keyName];
            if (formId) {
                new Hubspot().submit(user?.email || '', t("update-notification-succes"), t("wait-list-notification-error"), formId);
            }
        }
    }

    return (
        <div className={`pb-3 ${removeClasses ? "d-flex align-items-center" : "h-100"}`} style={{
            height: removeClasses ? '85%' : ''
        }}>
            <div className={`${removeClasses ? "" : "row m-2 rounded-08 shadow-01 h-100"} bg-white-100 position-relative`} style={{ marginBottom: 10 }}>
                <div className="m-auto d-flex flex-column justify-content-around align-items-center" style={{
                    width: '60%'
                }}>
                    {keyName && (
                        <img
                            src={imagePath}
                            alt={keyName}
                            style={{
                                width: "40%"
                            }}
                        />
                    )}
                    {
                        !removeClasses &&
                        keyName && (
                            <h1
                                className="mt-5 text-dark-100 font-sfpro-medium font-24px text-center"
                                dangerouslySetInnerHTML={{ __html: t(`${keyName}-title`) }}
                                style={{
                                    lineHeight: "32px"
                                }}
                            />
                        )
                    }
                    {
                        keyName && (
                            <div
                                className="my-2 text-dark-60 font-16px font-sfpro-regular text-center information-navigation"
                                dangerouslySetInnerHTML={{ __html: t(`${keyName}-subtitle`) }}
                                style={{
                                    lineHeight: "32px"
                                }}
                            />
                        )
                    }
                    {
                        keyName && UPGRADE_BUTTON.includes(keyName) &&
                        <form onSubmit={handleSubmitUpgrade} className="d-flex justify-content-center">
                            <button type="submit" className='btn-action m-1 px-5' style={{
                                width: "fit-content"
                            }}>
                                {t("common-upgrade")}
                            </button>
                        </form>
                    }
                    {
                        keyName && WAIT_LIST_BUTTON.includes(keyName) && (
                            <form onSubmit={handleSubmitWaitList} className="d-flex justify-content-center">
                                <button
                                    type="submit"
                                    className='btn-action m-1 px-5'
                                    style={{
                                        width: "fit-content"
                                    }}
                                >
                                    {t("common-wait-list")}
                                </button>
                            </form>
                        )
                    }
                    {
                        keyName && BLOCK_LIST.includes(keyName) && (
                            <button
                                className='btn-action m-1 px-5'
                                style={{
                                    width: "fit-content"
                                }}
                                onClick={() => h.push('/dashboard')}
                            >
                                {t("common-back-to-dashboard")}
                            </button>
                        )
                    }
                </div>
            </div>
        </div>
    );
}
