
import React, { ReactNode } from "react";

type SubtitleProps = {
    text: string;
    tag: ReactNode;
}

export const Subtitle = ({text, tag}: SubtitleProps) => {
    return (
        <div className="d-flex flex-row align-items-center justify-content-between mb-3">
            <h4 className="font-12px text-dark-100 m-0 font-sfpro-regular">{text}</h4>
            {tag}
        </div>
    )
}