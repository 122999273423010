import { ExternalLink } from 'UI/atoms/ExternalLink';
import { SelectFavorite } from 'UI/atoms/SelectFavorite';
import Notification from 'components/Elements/Notification';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ComplianceTable.css';
import { ComplianceDocument } from './Index';
import FixedNotification from 'UI/atoms/FixedNotification';

interface ComplianceTableProps {
  data: ComplianceDocument[];
  favorites: string[];
}

export const ComplianceTable: React.FC<ComplianceTableProps> = ({ data, favorites }) => {
  const { t } = useTranslation();
  const [loadingItems, setLoadingItems] = useState<{ [key: string]: boolean }>({});

  // Handle row click to redirect to external link
  const handleRowClick = (url: string) => {
    window.open(url, '_blank');
  };

  // Handle favorite toggle
  const handleFavoriteToggle = (e: React.MouseEvent, id: string) => {
    e.stopPropagation(); // Prevent row click event

    // Get current favorites from localStorage
    const savedFavorites = localStorage.getItem('complianceFavorites');
    let favoritesArray = savedFavorites ? JSON.parse(savedFavorites) : [];

    // Toggle favorite status
    if (favoritesArray.includes(id)) {
      favoritesArray = favoritesArray.filter((favId: string) => favId !== id);
      Notification.display("success", t("favorite-removed"));
    } else {
      favoritesArray.push(id);
      Notification.display("success", t("favorite-added"));
    }


    // Save updated favorites to localStorage
    localStorage.setItem('complianceFavorites', JSON.stringify(favoritesArray));

    // Dispatch event to notify about favorites change
    window.dispatchEvent(new CustomEvent('complianceFavoritesChanged'));
  };

  // Handle download button click
  const handleDownload = async (e: React.MouseEvent, url: string, title: string) => {
    e.stopPropagation(); // Prevent row click event

    // Set loading state for this item
    const documentId = getDocumentId(url);
    setLoadingItems(prev => ({ ...prev, [documentId]: true }));

    try {
      let downloadUrl = url;
      let fileId = '';

      // Check if it's a Google Drive URL
      if (url.includes('drive.google.com')) {
        // Extract file ID using regex for different Google Drive formats
        fileId = url.match(/\/(?:file|d)\/([^/]+)/)?.[1] ||
          url.match(/id=([^&]+)/)?.[1] ||
          '';

        if (fileId) {
          downloadUrl = `https://drive.google.com/uc?export=download&id=${fileId}`;
        } else {
          throw new Error('Invalid Google Drive URL');
        }
      }

      // Check if it's a Google Docs/Sheets/Slides URL
      else if (url.includes('docs.google.com')) {
        if (url.includes('/document/d/')) {
          fileId = url.match(/\/document\/d\/([^/]+)/)?.[1] || '';
          downloadUrl = `https://docs.google.com/document/d/${fileId}/export/docx`;
        } else if (url.includes('/spreadsheets/d/')) {
          fileId = url.match(/\/spreadsheets\/d\/([^/]+)/)?.[1] || '';
          downloadUrl = `https://docs.google.com/spreadsheets/d/${fileId}/export/xlsx`;
        } else if (url.includes('/presentation/d/')) {
          fileId = url.match(/\/presentation\/d\/([^/]+)/)?.[1] || '';
          downloadUrl = `https://docs.google.com/presentation/d/${fileId}/export/pptx`;
        }

        if (!fileId) {
          throw new Error('Invalid Google Docs URL');
        }
      }

      // Verificar si es necesario realizar un fetch o solo simular la descarga
      if (downloadUrl.includes('drive.google.com/uc?') || downloadUrl.includes('docs.google.com')) {
        // Para Google Drive/Docs, crear un enlace directo
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.target = '_self'; // Prevent opening in new tab

        // Set filename
        const fileName = title
          ? title.replace(/[^a-z0-9]/gi, '_').toLowerCase() + getFileExtension(url)
          : decodeURIComponent(url.split('/').pop()?.split('?')[0] || 'download');

        link.download = fileName;

        // Trigger download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        // Descargar el archivo con fetch para otros casos
        try {
          const response = await fetch(downloadUrl);
          if (!response.ok) {
            throw new Error('Error downloading file');
          }

          const blob = await response.blob();
          const tempUrl = window.URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = tempUrl;

          // Set filename
          const fileName = title
            ? title.replace(/[^a-z0-9]/gi, '_').toLowerCase() + getFileExtension(url)
            : decodeURIComponent(url.split('/').pop()?.split('?')[0] || 'download');

          link.download = fileName;

          // Trigger download
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(tempUrl);
        } catch (fetchError) {
          console.error('Error fetching file:', fetchError);
          // Fallback: open in new tab if download fails
          window.open(url, '_blank');
        }
      }
    } catch (error) {
      console.error('Error during download:', error);
      // Fallback: open in new tab if download fails
      window.open(url, '_blank');
      Notification.display("error", t("download-error"));
    } finally {
      // Add a small delay before removing loading state
      setTimeout(() => {
        setLoadingItems(prev => ({ ...prev, [documentId]: false }));
      }, 500);
    }
  };

  // Get appropriate file extension based on URL or format
  const getFileExtension = (url: string): string => {
    // Check for common file extensions in the URL
    const extensionMatches = {
      pdf: /\.pdf($|\?|#)/i,
      docx: /\.docx($|\?|#)/i,
      doc: /\.doc($|\?|#)/i,
      xlsx: /\.xlsx($|\?|#)/i,
      xls: /\.xls($|\?|#)/i,
      csv: /\.csv($|\?|#)/i,
      pptx: /\.pptx($|\?|#)/i,
      ppt: /\.ppt($|\?|#)/i,
    };

    for (const [ext, regex] of Object.entries(extensionMatches)) {
      if (regex.test(url)) {
        return `.${ext}`;
      }
    }

    // If no extension found in URL, infer from domain/path
    if (url.includes('docs.google.com')) {
      if (url.includes('/document/')) return '.docx';
      if (url.includes('/spreadsheets/')) return '.xlsx';
      if (url.includes('/presentation/')) return '.pptx';
    }

    // Default extension for unknown types
    return '.pdf';
  };

  // Get document ID from URL
  const getDocumentId = (url: string) => {
    return url.split('/').pop()?.split('?')[0] || '';
  };

  // Formatear fecha para mostrar
  const formatDate = (item: ComplianceDocument) => {
    // Usar createdAt si está disponible, de lo contrario usar date
    const dateString = item.createdAt || item.date;
    if (!dateString) return '';

    try {
      const date = new Date(dateString);

      // Formato: "Jan 15, 2025"
      const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      };

      return date.toLocaleDateString('en-US', options);
    } catch (e) {
      console.error('Error formatting date:', e);
      return dateString;
    }
  };

  // Determinar el formato basado en la URL si no está especificado
  const getFormat = (item: ComplianceDocument) => {
    if (item.format) return item.format;

    const url = item.url.toLowerCase();

    // Verificar dominios específicos primero
    try {
      const urlObj = new URL(url);
      const hostname = urlObj.hostname.toLowerCase();

      // Detección de blogs y sitios web
      if (hostname.includes('blog') ||
        hostname.includes('substack.com') ||
        hostname.startsWith('bcome.biz') ||
        url.includes('/blog/')) {
        return 'Blog';
      }

      // Detección específica para YouTube
      if (hostname.includes('youtube.com') || hostname.includes('youtu.be')) {
        return 'Video';
      }

      // Otras plataformas de audio/podcast
      if (hostname.includes('spotify.com') ||
        hostname.includes('soundcloud.com') ||
        hostname.includes('anchor.fm')) {
        return 'Podcast';
      }

      // Otras plataformas de video
      if (hostname.includes('vimeo.com')) {
        return 'Video';
      }

      // Google Sheets
      if (hostname === 'docs.google.com' && urlObj.pathname.toLowerCase().includes('/spreadsheets/d/')) {
        return 'Excel';
      }

      // Google Drive
      if (hostname.includes('drive.google.com')) {
        // Detectar PDF en Drive - solo si es un archivo específico, no una búsqueda
        if (urlObj.pathname.toLowerCase().includes('/file/d/')) {
          return 'PDF';
        }

        // Ignorar URLs de búsqueda de Drive
        if (urlObj.pathname.toLowerCase().includes('/drive/search')) {
          // Para URLs de búsqueda, intentar determinar por el título si contiene indicación del formato
          const title = item.title?.toLowerCase() || '';
          if (title.includes('pdf')) return 'PDF';
          if (title.includes('excel') || title.includes('xlsx') || title.includes('xls') || title.includes('csv')) return 'Excel';
          if (title.includes('word') || title.includes('doc')) return 'Word';
          if (title.includes('powerpoint') || title.includes('ppt')) return 'PowerPoint';
          if (title.includes('video')) return 'Video';
          if (title.includes('audio') || title.includes('podcast')) return 'Podcast';

          // Si no podemos determinar por el título, devolver Document genérico
          return 'Document';
        }

        const pathType = urlObj.pathname.split('/')[3]?.toLowerCase();
        if (pathType === 'document') return 'Word';
        if (pathType === 'presentation') return 'PowerPoint';
        if (pathType === 'spreadsheets') return 'Excel';
      }
    } catch (error) {
      // Si la URL no es válida, continuamos con la verificación de extensiones
      console.error('[ComplianceTable] Error al analizar URL:', error);
    }

    // Verificar extensiones de archivo
    const extensions = {
      pdf: /\.pdf($|\?|#)/i,
      excel: /\.(xlsx|xls|csv)($|\?|#)/i,
      word: /\.(docx|doc)($|\?|#)/i,
      powerpoint: /\.(pptx|ppt)($|\?|#)/i,
      video: /\.(mp4|webm|mov|avi)($|\?|#)/i,
      audio: /\.(mp3|wav|ogg)($|\?|#)/i
    };

    for (const [type, regex] of Object.entries(extensions)) {
      if (regex.test(url)) {
        switch (type) {
          case 'pdf': return 'PDF';
          case 'excel': return 'Excel';
          case 'word': return 'Word';
          case 'powerpoint': return 'PowerPoint';
          case 'video': return 'Video';
          case 'audio': return 'Podcast';
        }
      }
    }

    return 'Document';
  };

  // Determine the appropriate action icon based on format
  const getActionIcon = (format: string | undefined, url: string) => {
    // Verificar directamente si es una URL de YouTube
    if (url.toLowerCase().includes('youtube.com') || url.toLowerCase().includes('youtu.be')) {
      return 'link';
    }

    // Si format es undefined, intentar determinar por la URL
    if (!format) {
      format = getFormat({ url } as ComplianceDocument);
    }

    // For downloadable formats (PDF, Excel, Word, PowerPoint)
    if (['PDF', 'Excel', 'Word', 'PowerPoint', 'Document'].includes(format)) {
      return 'download';
    }

    // For external content (Video, Article, Podcast, Web, Blog)
    if (['Video', 'Article', 'Podcast', 'Web', 'Blog'].includes(format)) {
      return 'link';
    }

    // Verificar si es contenido audiovisual basado en el dominio
    try {
      const urlObj = new URL(url);
      const audiovisualDomains = [
        'youtube.com', 'youtu.be',
        'vimeo.com',
        'spotify.com',
        'soundcloud.com',
        'dailymotion.com',
        'ted.com',
        'twitch.tv'
      ];

      if (audiovisualDomains.some(domain => urlObj.hostname.includes(domain))) {
        return 'link';
      }
    } catch (error) {
      // Si hay error al parsear la URL, ignorar
    }

    // Default to download
    return 'download';
  };

  return (
    <div className="d-flex flex-column" style={{ position: 'relative', height: 'calc(100vh - 200px)' }}>
      <FixedNotification
        type="info"
        text="Upgrade your plan to access compliance data like B Corp, AGEC, and Scope 3."
        linkText="Contact us"
        linkUrl="mailto:info@bcome.biz"
        position="bottom-right"
        onLinkClick={() => {
          window.location.href = 'mailto:info@bcome.biz';
        }}
      />
      <div className="table-responsive" style={{ overflowY: 'auto', flex: '1 1 auto', paddingBottom: '48px' }}>
        <table className="table table-striped">
          <thead>
            <tr className='capitalize'>
              <th
                className="font-sfpro-regular text-dark-100 align-middle"
                style={{ width: "40%", paddingLeft: "16px" }}
              >{t('Title')}</th>
              <th className="font-sfpro-regular text-dark-100 align-middle" style={{ width: "15%" }}>{t('Category')}</th>
              <th className="font-sfpro-regular text-dark-100 align-middle" style={{ width: "15%" }}>{t('Format')}</th>
              <th className="font-sfpro-regular text-dark-100 align-middle" style={{ width: "15%" }}>{t('Date')}</th>
              <th className="font-sfpro-regular text-dark-100 align-middle" style={{ width: "7.5%" }}>{t('Action')}</th>
              <th className="font-sfpro-regular text-dark-100 align-middle" style={{ width: "7.5%" }}>{t('Favorites')}</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td colSpan={6} className="text-center py-4">
                  <span className="text-dark-60 font-14px">{t('no-results-found')}</span>
                </td>
              </tr>
            ) : (
              data.map((item) => {
                const documentId = getDocumentId(item.url);
                const isFavorite = favorites.includes(item.id);
                const isLoading = loadingItems[documentId];
                const format = getFormat(item);

                return (
                  <tr
                    key={item.id}
                    className="cursor-pointer table-item"
                    onClick={() => handleRowClick(item.url)}
                  >
                    <td
                      className="font-sfpro-regular letter-spacing-normal font-14px align-middle"
                      style={{ width: "40%", paddingLeft: "16px" }}
                    >
                      {item.title}
                    </td>
                    <td className="font-sfpro-regular letter-spacing-normal text-dark-100 font-14px align-middle" style={{ width: "15%" }}>
                      <span style={{
                        borderRadius: '24px',
                        background: '#f0f0f2',
                        padding: '4px 12px',
                        display: 'inline-block'
                      }}>
                        {item.section === 'Documents' ? 'My Documents' : item.section}
                      </span>
                    </td>
                    <td className="font-sfpro-regular letter-spacing-normal text-dark-100 font-14px align-middle" style={{ width: "15%" }}>
                      <span style={{
                        borderRadius: '24px',
                        background: '#f0f0f2 ',
                        padding: '4px 12px',
                        display: 'inline-block'
                      }}>
                        {format === 'Document' ? 'Article' : format}
                      </span>
                    </td>
                    <td className="font-sfpro-regular letter-spacing-normal text-dark-100 font-14px align-middle" style={{ width: "15%" }}>
                      {formatDate(item)}
                    </td>
                    <td className="align-middle" style={{ width: "7.5%" }}>
                      {isLoading ? (
                        <div className="d-flex justify-content-start">
                          <div
                            style={{
                              width: '24px',
                              height: '24px',
                              padding: '3px 5px 5px 3px',
                              borderRadius: '2px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              backgroundColor: 'transparent'
                            }}
                          >
                            <div className="spinner" />
                          </div>
                        </div>
                      ) : (
                        <div
                          className="d-flex justify-content-start"
                        >
                          {getActionIcon(format, item.url) === 'download' ? (
                            <div
                              style={{
                                width: '24px',
                                height: '24px',
                                padding: '3px 5px 5px 3px',
                                cursor: 'pointer',
                                borderRadius: '2px',
                                transition: 'background-color 0.2s ease',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'transparent'
                              }}
                              className="download-icon"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleDownload(e, item.url, item.title);
                              }}
                            >
                              <span
                                className="icon-icon-download font-20px"
                                style={{
                                  width: '16px',
                                  height: '16px'
                                }}
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                width: "24px",
                                height: "24px",
                                padding: "0px 5px 5px 5px",
                                cursor: "pointer",
                                borderRadius: "2px",
                                transition: "background-color 0.2s",
                                backgroundColor: "transparent"
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                window.open(item.url, '_blank');
                              }}
                            >
                              <ExternalLink href={item.url} />
                            </div>
                          )}
                        </div>
                      )}
                    </td>
                    <td className="align-middle" style={{ width: "7.5%" }}>
                      <div className="d-flex justify-content-start">
                        <SelectFavorite
                          select={isFavorite}
                          onClick={(e) => handleFavoriteToggle(e, item.id)}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <div
        className="d-flex flex-row align-items-center pl-3 pr-4 py-2"
        style={{
          borderTop: '1px solid #F0F0F2',
          width: '100%',
          background: '#FFFFFF',
          position: 'absolute',
          bottom: 0,
          left: 0
        }}
      >
        <div className="d-flex flex-row justify-content-between font-sfpro-regular letter-spacing-normal text-dark-100 font-14px">
          {data.length} {t('items')}
        </div>
      </div>
    </div>
  );
};
