import { useState, useEffect } from 'react';
import Article from '../services/http/Article';
import Dashboard from '../services/http/Dashboards';
import { QueryRankingResponse, RankingMetricKey } from '../types/ranking';

const articleService = new Article();
const dashboardService = new Dashboard();

// Definir las métricas permitidas
const ALLOWED_METRICS = {
    evaluation: ['Planet', 'People', 'Transparency', 'Circularity'],
    metrics: [
        'Savings_Water_Relative',
        'Savings_ClimateChange_Relative',
        'Savings_Eutrophication_Relative',
        'Savings_FossilFuels_Relative'
    ]
} as const;

export const useRankingData = (
    useNewEndpoint: boolean,
    projectId?: string,
    accountId?: string,
    metricKey?: RankingMetricKey['key']
) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        const fetchData = async () => {
            if (!useNewEndpoint) {
                return;
            }

            // Verificar si la métrica está permitida
            const isAllowedMetric = (key: string) => {
                return ALLOWED_METRICS.evaluation.includes(key as any) ||
                    ALLOWED_METRICS.metrics.includes(key as any);
            };

            if (!metricKey || !isAllowedMetric(metricKey)) {
                return;
            }

            setLoading(true);
            try {
                if (!projectId) {
                    throw new Error('ProjectId es requerido');
                }

                let rankingData;
                
                // Si es Circularity, usar el nuevo endpoint específico
                if (metricKey === 'Circularity') {
                    const response = await dashboardService.rankingPerformanceCircularity(projectId);
                    rankingData = response.data || response;
                } else {
                    const response = await articleService.queryRanking(projectId, metricKey);
                    rankingData = response.data || response;
                }

                if (!rankingData?.best || !rankingData?.worst) {
                    throw new Error('Formato de respuesta inválido');
                }

                // VERSION 1: Inversión temporal mientras el backend envía los datos al revés
                // Comentar este bloque y descomentar VERSION 2 cuando el backend corrija el orden
                const isSavingsMetric = metricKey.startsWith('Savings_');
                const isEcoScoreMetric = ALLOWED_METRICS.evaluation.includes(metricKey as any);
                const isCEIMetric = metricKey === 'Circularity';
                const shouldInvertValues = (isSavingsMetric || isEcoScoreMetric) && !isCEIMetric;

                // const transformedData = {
                //     [metricKey]: {
                //         bestArticles: shouldInvertValues ? rankingData.worst : rankingData.best,
                //         worstArticles: shouldInvertValues ? rankingData.best : rankingData.worst
                //     }
                // };

                //VERSION 2: Usar los datos directamente como vienen del backend
                // Descomentar este bloque y comentar VERSION 1 cuando el backend corrija el orden
                const transformedData = {
                    [metricKey]: {
                        bestArticles: rankingData.best,
                        worstArticles: rankingData.worst
                    }
                };

                setData(transformedData);
                setError(null);
            } catch (err) {
                const errorMsg = err instanceof Error ? err.message : 'Error al obtener los datos';
                setError(errorMsg);
                setData(null);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [useNewEndpoint, projectId, accountId, metricKey]);

    return { data, loading, error };
};
