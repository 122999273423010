import React, { useState, useRef } from 'react';
import { useProjects } from 'contexts/projectsContext';
import { ProjectsDropdown } from 'UI/atoms/DropDown';
import useOutsideClick from 'hooks/useOutsideClick.hook';

function SelectorProject({ isLoading }: { isLoading: boolean }) {
    const { projectSelected, setProjectSelected, openModalSaveChanges, setOpenModalSaveChanges } = useProjects();
    const [isShow, setIsShow] = useState(false)
    const [hoverSelectProject, setHoverSelectProject] = useState(false);
    const ref = useRef<HTMLHeadingElement>(null)

    const handleProjectSelected = (item: any) => {
        console.log(' Cambiando proyecto:', { 
            oldProject: projectSelected?.id, 
            newProject: item?.id,
            timestamp: new Date().toISOString()
        });
        if (setProjectSelected) setProjectSelected(item);
    }

    const projectClick = (project: any) => {
        handleProjectSelected(project);
        setIsShow(!isShow);
    }

    const handleOpenDropdown = () => {
        if (openModalSaveChanges.customView) {
            setOpenModalSaveChanges({
                ...openModalSaveChanges,
                open: true
            })
        } else {
            !isLoading && setIsShow(!isShow);
        }
    }

    useOutsideClick(ref, () => { setIsShow(false) });

    return (

        <div
            className="d-flex project-selector flex-column align-items-start"
            ref={ref}
            onClick={handleOpenDropdown}
        >
            <div
                className={`position-relative d-flex flex-row align-items-center justify-content-between nav-link cursor-pointer px-0 py-0  pr-1`}
                style={{
                    borderRadius: "30px",
                    minWidth: "200px",
                    maxWidth: "fit-content"
                }}
                onMouseEnter={() => !isLoading && setHoverSelectProject(true)}
                onMouseLeave={() => !isLoading && setHoverSelectProject(false)}
            >
                <span className={`text-dark-100 font-14px font-sfpro-regular text-overflow-ellipsis overflow-hidden`}>
                    {projectSelected?.name}
                </span>
                <div className={`d-flex align-items-center justify-content-center`}
                    style={{
                        height: "32px",
                        width: "32px",
                    }}>
                    <span
                        onClick={handleOpenDropdown}
                        className={`icon-icon-chevron-down text-dark-100 font-30px`}
                        style={{
                            transition: "all 0.5s ease",
                            transformOrigin: "50% 50%",
                            transform: (!isLoading && isShow) ? "rotate(180deg)" : "rotate(0deg)",
                        }}
                    />
                </div>
                {(!isLoading && isShow) &&
                    <ProjectsDropdown handleClick={projectClick} />}
            </div>
        </div>
    )
}


export default SelectorProject;