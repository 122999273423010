import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { IEcodesignRequest } from "./types/ecodesign";
import EcodesignCompare from "./EcodesignCompare";
import MaterialsCategoriesHttp from "services/http/MaterialsCategories";
import LcaCategoriesHttp from "services/http/LcaCategories";
import TransportsHttp from "services/http/Transports";
import ProcessHttp from "services/http/Process";
import FormSwitcher from "UI/molecules/FormSwitcher/Index";

interface IProps {
	request: IEcodesignRequest,
	setRequest: any,
    schema: any,
    dataOptions: any,
    setDataOptions: any,
    setEcoResults: any
}

// category option
const MaterialsCategories = new MaterialsCategoriesHttp();
const LcaCategories = new LcaCategoriesHttp();

// process option
const Transports = new TransportsHttp();
const Process = new ProcessHttp();


const EcodesignCompareForm = (props: IProps) => {
	const { request, setRequest, schema, dataOptions, setDataOptions, setEcoResults } = props;
    const [ isSwitched, setIsSwitched ] = useState<boolean>(false);

    const { stage } = request;
	const { t }: any = useTranslation();

    const categoryItemA = request.itemA.categoryId;
    const categoryItemB = request.itemB.categoryId;
    const processItemA = request.itemA.processId;
    const processItemB = request.itemB.processId;

    // Get categories
	useEffect(() => {
        if (stage === 'RM' || stage === 'MP') {
            const c = MaterialsCategories.getAllForEcoCalculator();
            c.then((res:any) => {
                const data = res.data;
                setDataOptions((prevState:any) => ({...prevState, category: data}));
            });
        } else {
            const c = LcaCategories.getAll();
            c.then((res:any) => {
                let data:any;
                if (stage === 'WP') {
                    data = res.data.data.filter((c:any) =>
                    c.name.includes('Dye') ||
                    c.name.includes('Print') ||
                    c.name.includes('Treatment')
                );
                }
                if (stage === 'P') {
                    data = res.data.filter((c:any) => c.name.includes('Packaging'));
                }
                setDataOptions((prevState:any) => ({...prevState, category: data}));
            });
        }
	}, [stage]);


    // Get processess and origins by item
    useEffect(() => {
        if (stage === 'T') {
            const t = Transports.getAllForEcoCalculator();
            t.then((res:any) => {
                const data = { process: res.data };
                setDataOptions((prevState:any) => ({...prevState, itemA: data}));
            });
        }
        if (stage === 'P') {
            const groupName = schema[stage].group;
            const p = Process.processByGroup(groupName);
            p.then((res:any) => {
                const data = { process: res.data, origins: [] };
                setDataOptions((prevState:any) => ({...prevState, itemA: data}));
            });
        }
        if (stage !== 'T' && stage !== 'P') {
            const groupName = schema[stage].group;
            if (categoryItemA) {
                const p = Process.processForCalculator(groupName, categoryItemA);
                p.then((res:any) => {
                    const data = { process: res.data, origins: [] };
                    setDataOptions((prevState:any) => ({...prevState, itemA: data}));
                });
            }
        }
	}, [categoryItemA, stage]);

    useEffect(() => {
        if (stage === 'T') {
            const t = Transports.getAllForEcoCalculator();
            t.then((res:any) => {
                const data = { process: res.data };
                setDataOptions((prevState:any) => ({...prevState, itemB: data}));
            });
        }
        if (stage === 'P') {
            const groupName = schema[stage].group;
            const p = Process.processByGroup(groupName);
            p.then((res:any) => {
                const data = { process: res.data, origins: [] };
                setDataOptions((prevState:any) => ({...prevState, itemB: data}));
            });
        }
        if (stage !== 'T' && stage !== 'P') {
            const groupName = schema[stage].group;
            if (categoryItemB) {
                const p = Process.processForCalculator(groupName, categoryItemB);
                p.then((res:any) => {
                    const data = { process: res.data, origins: [] };
                    setDataOptions((prevState:any) => ({...prevState, itemB: data}));
                });
            }
        }
	}, [categoryItemB, stage]);

    //  Set origins available depending on process selected by item
    useEffect(() => {
        if (stage !== 'T') {
            if (processItemA) {
                const originsFiltered = dataOptions.itemA.process.filter((p:any) => p.id === processItemA);
                const data = {
                    process: dataOptions.itemA.process,
                    origin: originsFiltered[0].origins
                }
                setDataOptions((prevState:any) => ({...prevState, itemA: data}));
            }
        }
	}, [processItemA, stage]);

    useEffect(() => {
        if (stage !== 'T') {
            if (processItemB) {
                const originsFiltered = dataOptions.itemB.process.filter((p:any) => p.id === processItemB);
                const data = {
                    process: dataOptions.itemB.process,
                    origin: originsFiltered[0].origins
                }
                setDataOptions((prevState:any) => ({...prevState, itemB: data}));
            }
        }
	}, [processItemB, stage]);

    return (
        <div className="mt-3">
            <h3 className="font-sfpro-medium font-12px text-dark-60 text-uppercase">{t(`ecodesign-compare-title-${schema[stage]?.label}`)}</h3>
            <EcodesignCompare request={request} setRequest={setRequest} dataOptions={dataOptions} schema={schema[stage]} scenario={'itemA'} order={1} isSwitched={isSwitched} />
            <FormSwitcher request={request} setRequest={setRequest} setDataOptions={setDataOptions} isSwitched={isSwitched} setIsSwitched={setIsSwitched} setEcoResults={setEcoResults} />
            <EcodesignCompare request={request} setRequest={setRequest} dataOptions={dataOptions} schema={schema[stage]} scenario={'itemB'} order={2} isSwitched={isSwitched} />
        </div>
    )
}

export default EcodesignCompareForm;
