import React, { useEffect, useState } from 'react';
import { CardGrid } from 'UI/molecules/CardGrid/Index';
import { LibraryResource, DataType } from 'loader/library-loader';
import { LayoutLibrary } from './LayoutLibrary';
import { filtersLibrary } from '../../../constants';
import LibraryHttp from 'services/http/Library';
import { queryToObj } from 'utils/Router';

// Quitar Favorites de los filtros
const allFilters = filtersLibrary;

// Versión de caché para las imágenes
const CACHE_VERSION = '1';

function Library() {
    const library = new LibraryHttp();
    const [filterSelected, setFilterSelected] = useState<string>(() => {
        const params = queryToObj(window.location.search);
        // Asegurarnos de que solo use los filtros estándar
        return filtersLibrary.includes(params.section) ? params.section : filtersLibrary[0];
    });
    const [searchText, setSearchText] = useState('');
    const [orderBy, setOrderBy] = useState<'newest' | 'oldest'>('newest');
    const [data, setData] = useState<LibraryResource[]>([]);
    const [favorites, setFavorites] = useState<{[key: string]: LibraryResource}>({});

    // Función para añadir control de versión a las URLs de imágenes
    const addVersionControl = (resources: LibraryResource[]): LibraryResource[] => {
        const savedVersion = localStorage.getItem('libraryImageVersion');
        
        // Si la versión cambió, actualizamos el timestamp
        if (savedVersion !== CACHE_VERSION) {
            localStorage.setItem('libraryImageVersion', CACHE_VERSION);
            localStorage.setItem('libraryImageTimestamp', Date.now().toString());
        }

        const timestamp = localStorage.getItem('libraryImageTimestamp') || Date.now().toString();

        return resources.map(resource => ({
            ...resource,
            cover: resource.cover ? `${resource.cover}${resource.cover.includes('?') ? '&' : '?'}_v=${timestamp}` : resource.cover
        }));
    };

    const handleFilter = (key: string, value: string) => {
        // Solo permitir cambiar a filtros estándar
        if (filtersLibrary.includes(value)) {
            setFilterSelected(value);
            
            // Construir la nueva URL manteniendo el estado de favoritos
            const currentParams = new URLSearchParams(window.location.search);
            const isFavoriteView = currentParams.get('filter') === 'favorites';
            
            const newParams = new URLSearchParams();
            if (isFavoriteView) {
                newParams.append('filter', 'favorites');
            }
            
            if (value !== filtersLibrary[0]) {
                newParams.append('section', value);
            }
            
            const queryString = newParams.toString();
            const newUrl = queryString 
                ? `${window.location.pathname}?${queryString}`
                : window.location.pathname;
            window.history.pushState({}, '', newUrl);
        }
    };
    
    const handleSearch = (key: string, value: string) => {
        setSearchText(value);
    };
    
    const handleOrder = (key: string, value: 'newest' | 'oldest') => {
        setOrderBy(value);
    };

    // Cargar favoritos del localStorage
    const loadFavorites = () => {
        const savedFavorites = localStorage.getItem('libraryFavorites');
        if (savedFavorites) {
            setFavorites(JSON.parse(savedFavorites));
        }
    };

    useEffect(() => {
        // Cargar datos y favoritos iniciales
        library.getAll().then((res) => {
            // Añadir versión a las URLs de imágenes
            setData(addVersionControl(res.data));
        });
        loadFavorites();

        // Escuchar cambios en favoritos
        const handleFavoritesChange = () => {
            loadFavorites();
        };

        window.addEventListener('favoritesChanged', handleFavoritesChange);
        
        return () => {
            window.removeEventListener('favoritesChanged', handleFavoritesChange);
        };
    }, []);

    const sortData = (items: LibraryResource[], order: 'newest' | 'oldest') => {
        const sorted = [...items].sort((a, b) => {
            const dateA = new Date(a.createdAt).getTime();
            const dateB = new Date(b.createdAt).getTime();
            const comparison = dateA - dateB;
            return order === 'newest' ? -comparison : comparison;
        });
        return sorted;
    };

    const filteredAndSortedData = sortData(
        data.filter((item: LibraryResource) => {
            const key = item.url || item.id;
            const isFavorite = !!favorites[key];
            const params = new URLSearchParams(window.location.search);
            const isInFavoritesView = params.get('filter') === 'favorites';

            // Si estamos en vista de favoritos y el item no es favorito, no lo mostramos
            if (isInFavoritesView && !isFavorite) {
                return false;
            }

            // Aplicar filtros normales
            if (filterSelected !== filtersLibrary[0]) {
                const filterToSection = {
                    "My Documents": DataType.documents,
                    "Resources": DataType.resources
                };
                
                const section = filterToSection[filterSelected as keyof typeof filterToSection];
                return item.section === section;
            }

            // Si es All Categories, mostrar todo (que haya pasado el filtro de favoritos si aplica)
            return true;
        }).filter((item: LibraryResource) => {
            return searchText === '' || item.title.toLowerCase().includes(searchText.toLowerCase());
        }),
        orderBy
    );

    const getFileType = (url: string): string => {
        if (!url) {
            return '';
        }

        const lowercaseUrl = url.toLowerCase();

        // Verificar dominios específicos primero
        try {
            const urlObj = new URL(url);
            const hostname = urlObj.hostname.toLowerCase();
            
            // Plataformas de audio/podcast
            if (hostname.includes('spotify.com') || 
                hostname.includes('soundcloud.com') || 
                hostname.includes('anchor.fm')) {
                return 'Podcast';
            }
            
            // Plataformas de video
            if (hostname.includes('youtube.com') || 
                hostname.includes('youtu.be') || 
                hostname.includes('vimeo.com')) {
                return 'Video';
            }

            // Google Drive
            if (hostname.includes('drive.google.com')) {
                const pathType = urlObj.pathname.split('/')[3]?.toLowerCase(); // /file/d/ or /document/d/
                
                if (pathType === 'document') return 'Word';
                if (pathType === 'spreadsheets') return 'Excel';
                if (pathType === 'presentation') return 'PowerPoint';
                // Para archivos normales, verificar la extensión
            }
        } catch (error) {
            console.warn('[FileType] Error parsing URL:', error);
        }

        // Verificar extensiones de archivo
        const extensions = {
            pdf: /\.pdf($|\?|#)/i,
            excel: /\.(xlsx|xls|csv)($|\?|#)/i,
            word: /\.(docx|doc)($|\?|#)/i,
            powerpoint: /\.(pptx|ppt)($|\?|#)/i,
            video: /\.(mp4|webm|mov|avi)($|\?|#)/i,
            audio: /\.(mp3|wav|ogg)($|\?|#)/i
        };

        for (const [type, regex] of Object.entries(extensions)) {
            if (regex.test(lowercaseUrl)) {
                
                switch(type) {
                    case 'pdf': return 'PDF';
                    case 'excel': return 'Excel';
                    case 'word': return 'Word';
                    case 'powerpoint': return 'PowerPoint';
                    case 'video': return 'Video';
                    case 'audio': return 'Podcast';
                }
            }
        }

        return '';
    };

    return (
        <section className="" style={{ height: '97%', overflowY: 'auto', borderRadius: '2px' }}>
            <LayoutLibrary
                keyName="library"
                title=""
                filters={allFilters}
                filterSelected={filterSelected}
                handleFilter={handleFilter}
                orderBy={orderBy}
                handleOrder={handleOrder}
                searchText={searchText}
                handleSearch={handleSearch}
            >
                <CardGrid data={filteredAndSortedData} />
            </LayoutLibrary>
        </section>
    );
}

export default Library;
