import React, { useState, useRef } from "react";
import { Popover, Tooltip } from "reactstrap";
import { TableViewOptionsDropDown } from "UI/atoms/DropDown";
import useOutsideClick from "hooks/useOutsideClick.hook";
import "./TableMenu.scss";
import { useTranslation } from "react-i18next";

interface Props {
	handleToggleColumns: () => void;
}

const TableMenu = (props: Props) => {
	const { handleToggleColumns } = props;

	const [isShowDropdown, setIsShowDropdown] = useState(false);
	const [isShowHover, setIsShowHover] = useState(false);
	const ref = useRef<HTMLHeadingElement>(null);
	const wPath = window.location.pathname;
	const { t } = useTranslation();

	useOutsideClick(ref, () => {
		setIsShowDropdown(false);
	});

	if (!wPath.includes("/digitization/smartlabels/articles") || wPath.includes("/digitization/smartlabels/articles")) {
		return null;
	}

	return (
		<div
			className={`table-menu d-flex align-items-center justify-content-center position-relative text-dark-60 font-sfpro-regular mx-3`}
			ref={ref}
			onClick={() => setIsShowDropdown(!isShowDropdown)}
		>
			<div
				id="tableActions"
				onMouseEnter={() => setIsShowHover(true)}
				onMouseLeave={() => setIsShowHover(false)}
			>
				<span className="icon-icon-download font-20px mx-1 cursor-pointer" />
			</div>

			{isShowDropdown && <TableViewOptionsDropDown />}
		</div>
	);
};

export default TableMenu;
