import React, { useEffect } from "react"
import { useLocation } from "react-router-dom";


export const SidebarDisplayer = ({setIsSideBar, isSideBar}:any) => {

    const location = useLocation();

    const marginLeft = isSideBar ? '-18px' : '12px';

    const handleClick = () => setIsSideBar(!isSideBar)

    if(location.pathname.endsWith('compliance')) return null;

    return (
        <div onClick={() => handleClick()}
            className="icon-icon-sidebar-button text-yellow cursor-pointer font-18px"
            style={{
                transition: "all 0.5s ease",
				transform: isSideBar ? "rotate(180deg)" : "rotate(0deg)",
                zIndex: 1,
                marginLeft: marginLeft,
            }}
        >
        </div>
    )
}