import React from "react";
import EUserRol from "types/EUserRol";

// import Home from "pages/Home/Home";
import Home from "UI/Pages/Home/Home";
import OnePageArticle from "UI/Pages/Articles/OnePageArticle";
import QRCode from "UI/Pages/Smartlabel/SmartLabel";
import EPlan from "types/EPlan";
import LcaArticles from "UI/Pages/LcaArticles/Index";
import DiagnosticsArticles from "UI/Pages/DiagnosisArticles/Index";
import EvaluationArticles from "UI/Pages/EvaluationsArticles/Index";
import SmartLabelsList from "UI/Pages/DigitizationSmartLabels/Index";
import MetricsLcaDashboard from "UI/organism/Dashboards/MetricsDashboard";
import EvaluationIndexDashboard from "UI/organism/Dashboards/IndexDashboard";
import Ecodesign from "UI/Pages/DiagnosisEcodesign/Ecodesign";
import { EcommerceCustomization } from "UI/Pages/DigitizationEcommerce/Customization";
import EcommerceIntegration from "UI/Pages/DigitizationEcommerce/Integration";
import Traceability from "UI/Pages/Traceability/Traceability";
import CEI from "UI/Pages/CEI/Index";
import TraceabilityDashboard from "UI/Pages/Traceability/Dashboard";
import Library from "UI/Pages/Library/Index";
import Insights from "UI/Pages/Insights/index";
import { DataCollection } from "UI/Pages/DataCollection/Index";
import { CEIDashboard } from "UI/Pages/CEI/Dashboard";
import { validateDataLoader } from "loader/validate-data-loader";
import Compliance from "UI/Pages/Compliance/Index";

export const PATHS = {
	login: "/login",
	old_home: "/home",
	home: "/",

	// Traceability
	traceabilityDashboard: "/traceability/plm/dashboard",
	traceability: "/traceability/plm/articles",
	traceabilitySupplier: "/traceability/plm/suppliers",
	traceabilityMaterials: "/traceability/plm/materials",
	traceabilityLogistics: "/traceability/plm/logistics",

	// Management
	management: "/traceability",
	dataCollection: "/traceability/data-collection/projects",
	// plm: "/traceability/plm",
	journeyMap: "/traceability/journey-map",

	//CEI
	ceiDashboard: "/metrics/cei/dashboard",
	cei: "/metrics/cei/articles",

	// Metrics
	metrics: "/metrics",
	metricsPlanetLca: "/metrics/lca",
	metricsLcaDashboard: "/metrics/lca/dashboard/:projectId",
	metricsLcaArticles: "/metrics/lca/articles",
	metricsPlanetLcaMaterials: "/metrics/lca/materials",
	// metricsBenchmarkDashboard: "/metrics/benchmark/dashboard/:projectId",
	metricsBenchmarkArticles: "/metrics/benchmark/articles",

	metricsPlanetSlca: "/metrics/slca",
	metricsPlanetSlcaArticles: "/metrics/slca/articles",
	metricsPlanetLcc: "/metrics/lcc",
	metricsPlanetLccArticles: "/metrics/lcc/articles",
	metricsPlanetMci: "/metrics/mci",
	metricsPlanetMciArticles: "/metrics/mci/articles",

	// Evaluation
	evaluation: "/evaluation",
	evaluationSi: "/evaluation/sustainabilityindex",
	evaluationSiDashboard:
		"/evaluation/sustainabilityindex/dashboard/:projectId",
	evaluationSiArticles: "/evaluation/sustainabilityindex/articles",

	// Diagnosis
	diagnosis: "/diagnosis",
	diagnosisBenchmark: "/diagnosis/benchmark",
	diagnosisBenchmarkMaterials: "/diagnosis/benchmark/materials",
	diagnosisEcodesign: "/diagnosis/ecodesign",
	diagnosisReporting: "/diagnosis/reporting",

	// Solutions
	solutions: "/solutions",

	// Digitization
	digitization: "/digitization",
	digitizationSmartLabel: "/digitization/smartlabels/articles",
	digitizatioEcommerceIntegration: "/digitization/ecommerce/integration",
	digitizatioEcommerceCustomization: "/digitization/ecommerce/customization",

	// Ecodesign
	ecodesign: "/ecodesign",
	academy: "/ecodesign/academy",
	glossary: "/ecodesign/glossary",
	calculator: "/ecodesign/calculator",

	// Article detail
	articles: "/articles",
	articlesOnePage: "/articles/:idArticle",
	articlesQRCode: "/articles/:idArticle/smartLabel",

	supply: "/supply",
	benchmarking: "/benchmarking",
	materials: "/materials",
	suppliers: "/suppliers",
	certificates: "/certificates",
	learningCenter: "/learningCenter",
	config: "/config",
	projects: "/projects/:id",

	mantainance: "/mantainance",

	//compliance
	compliance: "/compliance",

	// Library
	library: "/library",
};
export default PATHS;

interface IRoute {
	path: string;
	component: React.Component | any;
	roles: EUserRol[]; // Roles que necesita el usuario;
	plan?: EPlan[]; // Plan de la cuenta;
}

export const ROUTES: IRoute[] = [
	// Rutas para todos los roles
	// Home
	{
		path: PATHS.home,
		component: Home,
		roles: [EUserRol.ADMIN],
	},
	{
		path: PATHS.old_home,
		component: Home,
		roles: [EUserRol.ADMIN],
	},

	// Traceability dashboard
	{
		path: PATHS.traceabilityDashboard,
		component: validateDataLoader(TraceabilityDashboard, 'plm'),
		roles: [EUserRol.ADMIN]
	},
	// Traceability scope article
	{
		path: PATHS.traceability,
		component: Traceability,
		roles: [EUserRol.ADMIN],
	},
	//Scope supplier
	{
		path: PATHS.traceabilitySupplier,
		component: Traceability,
		roles: [EUserRol.ADMIN],
	},
	// Scope materials
	{
		path: PATHS.traceabilityMaterials,
		component: Traceability,
		roles: [EUserRol.ADMIN],
	},
	// Scope logistic
	{
		path: PATHS.traceabilityLogistics,
		component: Traceability,
		roles: [EUserRol.ADMIN],
	},

	// Management
	// {
	// 	path: PATHS.management,
	// 	component: Traceability,
	// 	roles: [EUserRol.ADMIN],
	// },
	{
		path: PATHS.dataCollection,
		component: validateDataLoader(DataCollection, 'data_collection'),
		roles: [EUserRol.ADMIN]
	},
	// {
	// 	path: PATHS.plm,
	// 	component: Traceability, // TODO TraceabilityEmptyData
	// 	roles: [EUserRol.ADMIN]
	// },
	// {
	// 	path: PATHS.journeyMap,
	// 	component: TraceabilityEmptyData,
	// 	roles: [EUserRol.ADMIN]
	// },

	// Metrics
	{
		path: PATHS.metrics,
		component: LcaArticles,
		roles: [EUserRol.ADMIN],
	},
	{
		path: PATHS.metricsLcaDashboard,
		component: validateDataLoader(MetricsLcaDashboard, 'lca'),
		roles: [EUserRol.ADMIN],
	},
	{
		path: PATHS.metricsLcaArticles,
		component: LcaArticles,
		roles: [EUserRol.ADMIN],
	},
	{
		path: PATHS.metricsBenchmarkArticles,
		component: DiagnosticsArticles,
		roles: [EUserRol.ADMIN],
	},
	{
		path: PATHS.ceiDashboard,
		component: validateDataLoader(CEIDashboard, 'cei'),
		roles: [EUserRol.ADMIN]
	},
	{
		path: PATHS.cei,
		component: CEI,
		roles: [EUserRol.ADMIN]
	},

	// Index
	{
		path: PATHS.evaluation,
		component: EvaluationArticles,
		roles: [EUserRol.ADMIN],
	},
	{
		path: PATHS.evaluationSiDashboard,
		component: validateDataLoader(EvaluationIndexDashboard, 'eco_score'),
		roles: [EUserRol.ADMIN],
	},
	{
		path: PATHS.evaluationSiArticles,
		component: EvaluationArticles,
		roles: [EUserRol.ADMIN],
	},

	// Diagnosis
	{
		path: PATHS.diagnosis,
		component: DiagnosticsArticles,
		roles: [EUserRol.ADMIN],
	},
	// Solutions
	{
		path: PATHS.solutions,
		component: Home,
		roles: [EUserRol.ADMIN],
	},

	// Digitization
	{
		path: PATHS.digitization,
		component: SmartLabelsList,
		roles: [EUserRol.ADMIN],
	},
	{
		path: PATHS.digitizationSmartLabel,
		component: validateDataLoader(SmartLabelsList, 'labeling'),
		roles: [EUserRol.ADMIN],
	},
	{
		path: PATHS.digitizatioEcommerceIntegration,
		component: EcommerceIntegration,
		roles: [EUserRol.ADMIN],
	},
	{
		path: PATHS.digitizatioEcommerceCustomization,
		component: validateDataLoader(EcommerceCustomization, 'configuration'),
		roles: [EUserRol.ADMIN],
	},

	// Ecodesign
	{
		path: PATHS.ecodesign,
		component: Ecodesign,
		roles: [EUserRol.ADMIN],
	},
	{
		path: PATHS.calculator,
		component: validateDataLoader(Ecodesign, 'stage_comparator'),
		roles: [EUserRol.ADMIN],
	},

	// Article detail
	{
		path: PATHS.articlesOnePage,
		component: OnePageArticle,
		roles: [EUserRol.ADMIN],
	},
	// Smartlabels  IMPORTANT: NEVER CHANGE THIS ROUTE!!!!!!
	{
		path: PATHS.articlesQRCode,
		component: QRCode,
		roles: [EUserRol.ADMIN],
	},
	{
		path: PATHS.compliance,
		component: Compliance,
		roles: [EUserRol.ADMIN],
	},
	{
		path: PATHS.library,
		component: validateDataLoader(Library, 'library'),
		roles: [EUserRol.ADMIN],
	}
];

/**
 *
 * @returns
 */
export function useRoutesByRole(roles: EUserRol[]): IRoute[] {
	return React.useMemo(() => {
		const ret: IRoute[] = [];
		ROUTES.forEach((route: IRoute) => {
			let i = 0;
			let hasRole = false;
			while (hasRole == false && i < roles.length) {
				hasRole = route.roles.indexOf(roles[i]) !== -1;
				i++;
			}
			if (hasRole) {
				ret.push(route);
			}
		});
		return ret;
	}, [roles]);
}
