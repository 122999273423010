import React, { useMemo, useState } from 'react';
import { Switch, Route, Redirect, RouteProps, useHistory } from 'react-router-dom';
import { HelmetProvider } from "react-helmet-async";

import AuthInterceptor from 'services/AuthInterceptor';

import {SpinnerLoader} from 'components/Common/Loader/Loader';
import BaseEmpty from 'components/Layout/BaseEmpty';
import Base from 'components/Layout/Base';
import LoginForm from 'UI/Pages/LoginForm/LoginForm';
import QRCode from "UI/Pages/Smartlabel/SmartLabel";
import StorageAuth from 'services/storage/Auth';

// Role Related
import AppPaths, { useRoutesByRole } from './AppPaths';
import MetaMenu, { ISidebarMenuItem } from "./MetaMenu";
import PermissionProvider from 'providers/permissionProvider';
import { ProjectsContextProvider } from 'contexts/projectsContext';
import { AccountContextProvider } from 'contexts/accountContext';
import { TableContextProvider } from 'contexts/tableContext';
import NotFound from 'UI/Pages/NotFound/Index';

import Maintainance from 'UI/Pages/Mantainance/Index';
import ErrorBoundry from 'error-boundry';

AuthInterceptor();

const ACTIVATE_MANTAINANCE_PAGE = true;

const Auth = new StorageAuth();

export default ({location}: RouteProps) => {

	const history = useHistory();
	const isLoggedIn = Auth.isTokenAlive(Auth.getSession());
	const [permissionAccess, setPermissionAccess]: string | any = useState([]);
	const routes = useRoutesByRole(permissionAccess);
	const routesComponents: JSX.Element[] = useMemo(() => {
		return routes.map(route => <Route path={route.path} exact component={route.component} key={route.path} />);
	}, [routes]);
	const metaMenuRoutes: ISidebarMenuItem[] = routes.flatMap(route => MetaMenu.filter(menu => menu.path === route.path));

	// if (ACTIVATE_MANTAINANCE_PAGE) {
	// 	return (
	// 		<PermissionProvider.Provider value={[permissionAccess, setPermissionAccess]}>
	// 			<Switch location={location}>
	// 				<Route path={AppPaths.mantainance} component={Maintainance} />
	// 				<Redirect to={AppPaths.mantainance} />
	// 			</Switch>
	// 		</PermissionProvider.Provider>
	// 	)
	// }

	if (!isLoggedIn) {
		return ( // Login Form
			<BaseEmpty>
				<PermissionProvider.Provider value={[permissionAccess, setPermissionAccess]}>
					<Switch location={location}>
						<Route path={AppPaths.articlesQRCode} component={QRCode} />
						<Route path={AppPaths.login} component={LoginForm} />
						<Redirect to={AppPaths.login} />
						history.push(AppPaths.Login);
					</Switch>
				</PermissionProvider.Provider>
			</BaseEmpty>
		);
	}
	else {

		const fallback = (
			<div className="page-loader d-flex flex-row justify-content-center" style={{height: '100vh'}}>
				<SpinnerLoader width={"150px"} height={"150px"} color="black"/>
			</div>
		)

		return (
			<PermissionProvider.Provider value={[permissionAccess, setPermissionAccess]}>
				<AccountContextProvider>
					<ProjectsContextProvider>
						<HelmetProvider>
							<TableContextProvider>
								<Base menu={metaMenuRoutes} location={location} history={history}>
									<div style={{ flexGrow: 1, flexShrink: 1, flexBasis: 0, overflowX: 'hidden', overflowY: 'auto'}}>
										<Switch location={location}>
											{isLoggedIn && location?.pathname === AppPaths.login && (
												<Redirect to={AppPaths.home} />
											)}
											<ErrorBoundry fallback={<NotFound />}>
												{routesComponents}
											</ErrorBoundry>
											<Route path='*' exact={true} component={NotFound} />
										</Switch>
									</div>
								</Base>
							</TableContextProvider>
						</HelmetProvider>
					</ProjectsContextProvider>
				</AccountContextProvider>
			</PermissionProvider.Provider>
		);
	}
};
