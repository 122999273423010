import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SpinnerLoader } from 'components/Common/Loader/Loader';
import { useProjects } from 'contexts/projectsContext';
import { ComplianceTable } from './ComplianceTable';
import LibraryService from 'services/http/Library';
import { HeaderTop } from 'UI/organism/PageHeader/Index';
import { useAccount } from 'contexts/accountContext';
import { useHistory } from 'react-router-dom';
import { SelectFavorite } from 'UI/atoms/SelectFavorite';
import { InformationNavigation } from 'UI/molecules/InformationNavigation';

export interface ComplianceDocument {
  id: string;
  title: string;
  category: string;
  format?: string;
  date?: string;
  url: string;
  isFavorite?: boolean;
  cover?: string;
  section?: string;
  type?: string;
  createdAt?: string;
  updatedAt?: string;
  accountId?: string;
  account?: any;
}

const Compliance = () => {
  const { t } = useTranslation();
  const { projectSelected } = useProjects();
  // Initialize isLoading to true to ensure loader is shown immediately
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [documents, setDocuments] = useState<ComplianceDocument[]>([]);
  const [filteredDocuments, setFilteredDocuments] = useState<ComplianceDocument[]>([]);
  const [filterSelected, setFilterSelected] = useState<string>(t('All categories'));
  const [orderBy, setOrderBy] = useState<'newest' | 'oldest'>('newest');
  const [searchText, setSearchText] = useState<string>('');
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
  const [showFavorites, setShowFavorites] = useState<boolean>(false);
  const [favorites, setFavorites] = useState<string[]>([]);
  const [activeDropdown, setActiveDropdown] = useState<'filter' | 'sort' | null>(null);
  const history = useHistory();
  const { user } = useAccount();
  const [categories, setCategories] = useState<string[]>(['All categories']);

  // Inicializar el estado basado en los parámetros de URL
  useEffect(() => {
    // Verificar si hay un parámetro 'filter=favorites' en la URL
    const params = new URLSearchParams(window.location.search);
    const filterParam = params.get('filter');

    // Si el parámetro es 'favorites', activar la vista de favoritos
    if (filterParam === 'favorites') {
      setShowFavorites(true);
    } else {
      setShowFavorites(false);
    }

    // Verificar si hay un parámetro 'category' en la URL
    const categoryParam = params.get('category');
    if (categoryParam) {
      setFilterSelected(categoryParam);
    }
  }, []);

  // Escuchar cambios en la URL para actualizar el estado
  useEffect(() => {
    const handleLocationChange = () => {
      const params = new URLSearchParams(window.location.search);
      const filterParam = params.get('filter');

      // Actualizar el estado de favoritos según la URL
      if (filterParam === 'favorites') {
        setShowFavorites(true);
      } else {
        setShowFavorites(false);
      }

      // Actualizar el filtro de categoría si está presente
      const categoryParam = params.get('category');
      if (categoryParam) {
        setFilterSelected(categoryParam);
      } else if (!categoryParam && filterSelected !== t('All categories')) {
        setFilterSelected(t('All categories'));
      }
    };

    // Escuchar el evento popstate (cuando se usa el botón atrás/adelante)
    window.addEventListener('popstate', handleLocationChange);

    return () => {
      window.removeEventListener('popstate', handleLocationChange);
    };
  }, [filterSelected, t]);

  // Load initial data
  useEffect(() => {
    // Set loading state to true at the beginning of the effect
    setIsLoading(true);

    const loadData = async () => {
      try {
        // Load favorites from localStorage
        const savedFavorites = localStorage.getItem('complianceFavorites');
        if (savedFavorites) {
          const parsedFavorites = JSON.parse(savedFavorites);
          setFavorites(parsedFavorites);
        }

        // Verificar si hay un proyecto seleccionado
        if (!projectSelected) {
          setDocuments([]);
          setFilteredDocuments([]);
          // Only set loading to false after we have a definitive response
          setIsLoading(false);
          return;
        }

        // Verificar si el proyecto tiene accountId
        if (!projectSelected.accountId) {
          setDocuments([]);
          setFilteredDocuments([]);
          // Only set loading to false after we have a definitive response
          setIsLoading(false);
          return;
        }

        // Continuar con la carga de documentos
        const libraryService = new LibraryService();
        const response = await libraryService.getComplianceDocuments(projectSelected.accountId);

        // Transformar la respuesta en un array de documentos
        let complianceDocuments: ComplianceDocument[] = [];

        if (response && response.data) {
          if (response.data === '') {
            setDocuments([]);
            setFilteredDocuments([]);
            // Only set loading to false after we have a definitive response
            setIsLoading(false);
            return;
          }

          complianceDocuments = Array.isArray(response.data)
            ? response.data
            : [response.data];

        }

        if (complianceDocuments.length > 0) {
          // Ensure "My Documents" and "Resources" always appear in that order
          const uniqueSections = ['All categories'];

          // Always add "My Documents" first
          uniqueSections.push('My Documents');

          // Always add "Resources" second
          uniqueSections.push('Resources');

          setCategories(uniqueSections);
        }

        // Agregar información de sección si no existe
        const processedDocuments = complianceDocuments.map(doc => {
          // Si el documento no tiene sección, asignarle 'Documents' por defecto
          if (!doc.section) {
            return { ...doc, section: 'Documents' };
          }
          return doc;
        });

        setDocuments(processedDocuments);
        setFilteredDocuments(processedDocuments);
        // Only set loading to false after we have a definitive response
        setIsLoading(false);
      } catch (error) {
        console.error('[Compliance] Error loading compliance documents:', error);
        setDocuments([]);
        setFilteredDocuments([]);
        // Only set loading to false after we have a definitive response
        setIsLoading(false);
      }
    };

    // Execute loadData without immediately setting loading to false
    loadData();

    // Listen for favorites changes
    const handleFavoritesChange = () => {
      const savedFavorites = localStorage.getItem('complianceFavorites');
      if (savedFavorites) {
        const parsedFavorites = JSON.parse(savedFavorites);
        setFavorites(parsedFavorites);

        // Si estamos en la vista de favoritos, actualizar la vista inmediatamente
        if (showFavorites) {
          const favoritesOnly = documents.filter(doc => parsedFavorites.includes(doc.id));
          setFilteredDocuments(favoritesOnly);
        }
      } else {
        setFavorites([]);

        // Si estamos en la vista de favoritos y no hay favoritos, mostrar vista vacía
        if (showFavorites) {
          setFilteredDocuments([]);
        }
      }
    };

    window.addEventListener('complianceFavoritesChanged', handleFavoritesChange);

    return () => {
      window.removeEventListener('complianceFavoritesChanged', handleFavoritesChange);
    };
  }, [projectSelected]);

  // Close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (activeDropdown) {
        const target = event.target as HTMLElement;
        if (!target.closest('.dropdown-menu') && !target.closest('.nav-link')) {
          setActiveDropdown(null);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeDropdown]);

  // Aplicar filtros, search, y sorting
  useEffect(() => {
    let result = [...documents];

    // Aplicar filtro de categoría
    if (filterSelected !== t('All categories')) {
      // Si el filtro es "My Documents", buscar documentos con section "Documents"
      const filterValue = filterSelected === 'My Documents' ? 'Documents' : filterSelected;
      result = result.filter(doc => doc.section === filterValue);
    }

    // Aplicar filtro de búsqueda
    if (searchText) {
      const searchLower = searchText.toLowerCase();
      result = result.filter(doc =>
        doc.title.toLowerCase().includes(searchLower) ||
        doc.category.toLowerCase().includes(searchLower) ||
        (doc.section && doc.section.toLowerCase().includes(searchLower))
      );
    }

    // Aplicar filtro de favoritos
    if (showFavorites) {
      result = result.filter(doc => favorites.includes(doc.id));
    }

    // Aplicar sorting
    result.sort((a, b) => {
      const dateFieldA = a.createdAt || a.date || '';
      const dateFieldB = b.createdAt || b.date || '';

      const dateA = new Date(dateFieldA).getTime();
      const dateB = new Date(dateFieldB).getTime();
      return orderBy === 'newest' ? dateB - dateA : dateA - dateB;
    });

    setFilteredDocuments(result);
  }, [documents, filterSelected, orderBy, searchText, showFavorites, favorites, t]);

  // Handle filter change
  const handleFilter = (value: string) => {
    if (value === 'Documents') {
      setFilterSelected('My Documents');
    } else {
      setFilterSelected(value);
    }
  };

  // Handle order change
  const handleOrder = (value: 'newest' | 'oldest') => {
    setOrderBy(value);
  };

  // Handle search
  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  // Toggle favorites view
  const handleFavoritesToggle = (e?: React.MouseEvent) => {
    if (e) e.preventDefault();

    const newShowFavorites = !showFavorites;

    setShowFavorites(newShowFavorites);

    if (newShowFavorites) {
      const favoritesOnly = documents.filter(doc => favorites.includes(doc.id));
      setFilteredDocuments(favoritesOnly);

      const params = new URLSearchParams();
      params.append('filter', 'favorites');
      if (filterSelected !== t('All categories')) {
        params.append('category', filterSelected);
      }

      const queryString = params.toString();
      history.push(`/compliance?${queryString}`);
    } else {
      let result = [...documents];

      if (filterSelected !== t('All categories')) {
        // Si el filtro es "My Documents", buscar documentos con section "Documents"
        const filterValue = filterSelected === 'My Documents' ? 'Documents' : filterSelected;
        result = result.filter(doc => doc.section === filterValue);
      }

      if (searchText) {
        const searchLower = searchText.toLowerCase();
        result = result.filter(doc =>
          doc.title.toLowerCase().includes(searchLower) ||
          doc.category.toLowerCase().includes(searchLower) ||
          (doc.section && doc.section.toLowerCase().includes(searchLower))
        );
      }

      result.sort((a, b) => {
        const dateFieldA = a.createdAt || a.date || '';
        const dateFieldB = b.createdAt || b.date || '';

        const dateA = new Date(dateFieldA).getTime();
        const dateB = new Date(dateFieldB).getTime();
        return orderBy === 'newest' ? dateB - dateA : dateA - dateB;
      });

      setFilteredDocuments(result);

      const params = new URLSearchParams();
      if (filterSelected !== t('All categories')) {
        params.append('category', filterSelected);
      }

      const queryString = params.toString();
      if (queryString) {
        history.push(`/compliance?${queryString}`);
      } else {
        history.push('/compliance');
      }
    }
  };

  // Toggle search bar
  const toggleSearch = () => {
    setIsSearchActive(!isSearchActive);
    if (isSearchActive && searchText) {
      setSearchText('');
    }
  };

  // Reintentar con la API
  const handleRetry = () => {
    window.location.reload();
  };

  // Handle contact us click for upgrade
  const handleContactUs = () => {
    window.open('https://bcome.biz/contact/', '_blank');
  };

  // Verificar si estamos en la ruta /compliance o cualquier subruta
  const isCompliancePath = window.location.pathname.startsWith('/compliance');

  return (
    <div className="container-fluid p-0">
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="m-auto">
            <SpinnerLoader color={"#1C1C30"} width={"60px"} height={"60px"} />
          </div>
        </div>
      ) : (
        <>
          <div className="w-100 d-flex flex-column align-items-center justify-content-between">
            <HeaderTop userName={user?.name} />
            <h1 className={`pb-0 mb-0 px-3 text-dark-100 font-sfpro-regular font-18px w-100 pt-4 mb-4 text-capitalize`} style={{ height: '42px' }}>
              {t(`compliance`)}
            </h1>
          </div>

          <div className={isCompliancePath ? "" : "px-3"}>
            <header className={`d-flex flex-row align-items-center justify-content-between w-100 pr-3 ${isCompliancePath ? "pl-3" : ""}`} style={{ position: 'relative' }}>
              <div className="d-flex flex-row align-items-center gap-4">
                <div className="d-flex flex-column align-items-start">
                  <div className="d-flex flex-column align-items-start" style={{ position: 'relative', zIndex: 1000 }}>
                    <div
                      className="position-relative d-flex flex-row align-items-center nav-link cursor-pointer px-0 py-0 pr-1"
                      onClick={() => setActiveDropdown(activeDropdown === 'filter' ? null : 'filter')}
                    >
                      <span className="text-dark-100 font-14px font-sfpro-regular text-overflow-ellipsis overflow-hidden">
                        {filterSelected === 'Documents' ? 'My Documents' : filterSelected}
                      </span>
                      <div className="d-flex align-items-center justify-content-center" style={{ height: '32px', width: '32px' }}>
                        <span
                          className="icon-icon-chevron-down font-30px"
                          style={{
                            transition: 'all 0.5s ease',
                            transformOrigin: '50% 50%',
                            transform: activeDropdown === 'filter' ? 'rotate(180deg)' : 'rotate(0deg)'
                          }}
                        ></span>
                      </div>
                      {activeDropdown === 'filter' && (
                        <div
                          className="dropdown-menu show position-absolute"
                          style={{ left: "auto", width: "280px", zIndex: 1001 }}
                        >
                          {categories.map((category: string) => (
                            <button
                              key={`filter-${category}`}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleFilter(category);
                                setActiveDropdown(null);
                              }}
                              className={
                                filterSelected === category
                                  ? "selected-project font-sfpro-medium d-flex align-items-center dropdown-item"
                                  : "dropdown-item"
                              }
                            >
                              {filterSelected === category && (
                                <span className="icon-icon-tick mr-2 text-dark-100 font-18px"></span>
                              )}
                              <div
                                className={`${filterSelected === category
                                  ? "font-sfpro-medium"
                                  : "font-sfpro-regular"
                                  }
                                text-overflow-ellipsis overflow-hidden`}
                              >
                                {category === 'Documents' ? 'My Documents' : category}
                              </div>
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-start">
                  <div className="d-flex flex-column align-items-start" style={{ position: 'relative', zIndex: 1000 }}>
                    <div
                      className="position-relative d-flex flex-row align-items-center nav-link cursor-pointer px-0 py-0 pl-3 pr-1"
                      onClick={() => setActiveDropdown(activeDropdown === 'sort' ? null : 'sort')}
                    >
                      <span className="text-dark-100 font-14px font-sfpro-regular text-overflow-ellipsis overflow-hidden">
                        Sort: {orderBy === 'newest' ? 'Newest' : 'Oldest'}
                      </span>
                      <div className="d-flex align-items-center justify-content-center" style={{ height: '32px', width: '32px' }}>
                        <span
                          className="icon-icon-chevron-down font-30px"
                          style={{
                            transition: 'all 0.5s ease',
                            transformOrigin: '50% 50%',
                            transform: activeDropdown === 'sort' ? 'rotate(180deg)' : 'rotate(0deg)'
                          }}
                        ></span>
                      </div>
                      {activeDropdown === 'sort' && (
                        <div
                          className="dropdown-menu show position-absolute"
                          style={{ left: "auto", width: "280px", zIndex: 1001 }}
                        >
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOrder('newest');
                              setActiveDropdown(null);
                            }}
                            className={
                              orderBy === 'newest'
                                ? "selected-project font-sfpro-medium d-flex align-items-center dropdown-item"
                                : "dropdown-item"
                            }
                          >
                            {orderBy === 'newest' && (
                              <span className="icon-icon-tick mr-2 text-dark-100 font-18px"></span>
                            )}
                            <div
                              className={`${orderBy === 'newest'
                                ? "font-sfpro-medium"
                                : "font-sfpro-regular"
                                }
                              text-overflow-ellipsis overflow-hidden`}
                            >
                              Newest
                            </div>
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOrder('oldest');
                              setActiveDropdown(null);
                            }}
                            className={
                              orderBy === 'oldest'
                                ? "selected-project font-sfpro-medium d-flex align-items-center dropdown-item"
                                : "dropdown-item"
                            }
                          >
                            {orderBy === 'oldest' && (
                              <span className="icon-icon-tick mr-2 text-dark-100 font-18px"></span>
                            )}
                            <div
                              className={`${orderBy === 'oldest'
                                ? "font-sfpro-medium"
                                : "font-sfpro-regular"
                                }
                              text-overflow-ellipsis overflow-hidden`}
                            >
                              Oldest
                            </div>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row align-items-center">
                <h3 className="font-20px text-dark-100 m-0 pr-5 font-sfpro-regular"></h3>
                <div className="d-flex align-items-center">
                  <div className="search-bar d-flex flex-row-reverse align-items-center">
                    {isSearchActive ? (
                      <div className="position-relative d-flex align-items-center mr-3">
                        <input
                          type="text"
                          className="form-control border-0 pl-4 pr-4 py-2 rounded-pill bg-light"
                          placeholder={t('search')}
                          value={searchText}
                          onChange={(e) => handleSearch(e.target.value)}
                          style={{
                            width: '200px',
                            height: '36px',
                            fontSize: '14px',
                            transition: 'all 0.3s ease',
                          }}
                          autoFocus
                        />
                        <span
                          className="icon-icon-search position-absolute"
                          style={{ left: '12px', fontSize: '16px', color: '#6c757d' }}
                        ></span>
                        {searchText && (
                          <span
                            className="icon-icon-close position-absolute cursor-pointer"
                            style={{ right: '12px', fontSize: '16px', color: '#6c757d' }}
                            onClick={() => handleSearch('')}
                          ></span>
                        )}
                      </div>
                    ) : (
                      <div id="search-bar-compliance" className="d-flex align-items-center text-dark-60 text-dark-100-hover" onClick={toggleSearch}>
                        <span className="icon-icon-search font-20px" style={{ margin: '6px' }}></span>
                      </div>
                    )}
                  </div>
                  <div style={{ cursor: 'pointer' }}>
                    <SelectFavorite
                      select={showFavorites}
                      onClick={(e) => handleFavoritesToggle(e)}
                    />
                  </div>
                </div>
              </div>
            </header>

            <div className="mt-4">
              {filteredDocuments.length === 0 ? (
                showFavorites ? (
                  <div className='grid-container p-2'>
                    <div className="empty-favorites">
                      <p>You currently have no saved items.</p>
                      <button onClick={() => {
                        history.push('/compliance');
                        setShowFavorites(false);
                      }}>
                        Back to all items
                      </button>
                    </div>
                  </div>
                ) : filterSelected === 'My Documents' ? (
                  <div style={{flexGrow: 1}}>
                    <div className='grid-container p-2'>
                      <div className="empty-favorites">
                        <p>You currently have no compliance documents available.</p>
                        <button onClick={() => {
                          window.location.href = 'mailto:info@bcome.biz';
                        }}>
                          Contact us
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null
              ) : (
                <ComplianceTable
                  data={filteredDocuments}
                  favorites={favorites}
                />
              )}
            </div>
          </div>
        </>
      )}
      <style>
        {
          `
          .grid-container {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        gap: 24px;
                        width: 100%;
                        justify-content: start;
                        position: relative;
                        min-height: ${(showFavorites || filterSelected === 'My Documents' || (!showFavorites && filterSelected !== 'My Documents' && filteredDocuments.length === 0)) ? 'calc(100vh - 200px)' : 'auto'};
                    }
                    .empty-favorites {
                        width: 320px;
                        padding: 24px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 16px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                    .empty-favorites p {
                        margin: 0;
                        color: var(--New-Palette-Dark-100, #1C1C30);
                        text-align: center;
                    }
                    .empty-favorites button {
                        display: flex;
                        padding: 12px 32px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        align-self: stretch;
                        border-radius: 4px;
                        background: var(--New-Palette-Dark-100, #1C1C30);
                        color: white;
                        border: none;
                        cursor: pointer;
                        transition: opacity 0.2s ease;
                    }
          `
        }
      </style>
    </div>
  );
};

export default Compliance;
