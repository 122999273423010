// GLOBAL CONSTANTS
// -----------------------------------

export const APP_COLORS = {
	primary: '#5d9cec',
	success: '#00B16E', //27c24c
	info: '#007EEF', //23b7e5
	warning: '#ff902b',
	danger: '#FF675D', //f05050
	inverse: '#131e26',
	green: '#37bc9b',
	pink: '#f532e5',
	purple: '#7266ba',
	dark: '#3a3f51',
	yellow: '#fad732',
	'gray-darker': '#232735',
	'gray-dark': '#3a3f51',
	gray: '#dde6e9',
	'gray-light': '#e4eaec',
	'gray-lighter': '#edf1f2'
};

export const APP_MEDIAQUERY = {
	desktopLG: 1200,
	desktop: 992,
	tablet: 768,
	mobile: 480
};

export const APP_MODALS = {
	modalNewProject: 'MODAL_NEW_PROJECT',
	modalNewProjectArticle: 'MODAL_NEW_PROJECT_ARTICLE',
	modalNewMaterial: 'MODAL_NEW_MATERIAL',
};

export const APP_ENTITY_NAMES = {
	projects: 'projects',
	digitization: 'digitization',
	articles: 'articles',
	materials: 'materials',
	suppliers: 'suppliers',
};

interface SCOPES_BY_ARTICLE_INTERFACE {
	[key: string]: string[];
}
export const SCOPES_BY_ARTICLE: SCOPES_BY_ARTICLE_INTERFACE = {
	"lca": ["Articles"],
	"benchmark": ["Articles"],
	"sustainabilityindex": ["Articles"],
	"smartlabels": ["Articles"],
	"plm": ["articles", "materials", "suppliers"],
	"cei": ["articles"],
}

export const MATERIAL = 'material'
export const TRACEABILITY = "Traceability"
export const ARTICLE = 'article'
export const PLM = 'plm'

export const REFERENCES_TYPE = ['material', 'second_hand']

export const NO_DECIMAL_VALUES = ['Traceability_Suppliers', 'Traceability_Certificates']

export const LCA = 'LCA'
export const ECO_SCORE = 'Eco SCore'
export const PLM_DASHBOARD = 'PLM'
export const ARTICLE_DASHBOARD = 'Article'

export const downloadFileName: any = {
	"metrics": LCA,
	"evaluation": ECO_SCORE,
	"traceability": PLM_DASHBOARD,
	"articles": ARTICLE_DASHBOARD,
}

type HelpContent = {
	question: string,
	link: string
}

export const HELP_LINKS: Record<string, HelpContent> = {
	"plm_articles": {
		question: "Struggling to understand this data?",
		link: "https://bcome.biz/help-center/tutorials/control-your-supply-chain-with-the-plm-tool/"
	},
	"plm_materials": {
		question: "Struggling to understand this data?",
		link: "https://bcome.biz/help-center/tutorials/control-your-supply-chain-with-the-plm-tool/"
	},
	"plm_suppliers": {
		question: "Struggling to understand this data?",
		link: "https://bcome.biz/help-center/tutorials/control-your-supply-chain-with-the-plm-tool/"
	},
	"plm_dashboard": {
		question: "Need guidance interpreting the dashboard?",
		link: "https://bcome.biz/help-center/tutorials/navigate-the-plm-dashboard/"
	},
	"lca_articles": {
		question: "Struggling to understand this data?",
		link: "https://bcome.biz/help-center/tutorials/uncover-your-environmental-impact/"
	},
	"lca_dashboard": {
		question: "Need guidance interpreting the dashboard?",
		link: "https://bcome.biz/help-center/tutorials/view-the-lca-dashboard/"
	},
	"sustainabilityindex_articles": {
		question: "Struggling to understand this data?",
		link: "https://bcome.biz/help-center/tutorials/rate-the-full-impact-of-your-items-with-the-ecoscore-tool/"
	},
	"sustainabilityindex_dashboard": {
		question: "Need guidance interpreting the dashboard?",
		link: "https://bcome.biz/help-center/tutorials/review-the-eco-score-dashboard/"
	},
	"cei_articles": {
		question: "Struggling to understand this data?",
		link: "https://bcome.biz/help-center/tutorials/assess-the-circularity-impact-with-the-cei-tool/"
	},
	"cei_dashboard": {
		question: "Need guidance interpreting the dashboard?",
		link: "https://bcome.biz/help-center/tutorials/assess-the-circularity-impact-with-the-cei-tool/"
	},
	"smartlabels_articles": {
		question: "Struggling to understand this data?",
		link: "https://bcome.biz/help-center/tutorials/share-your-data-via-ecommerce-integration-labeling/"
	},
	"calculator_": {
		question: "Struggling to understand this data?",
		link: "https://bcome.biz/help-center/tutorials/compare-your-material-selection-with-the-ecocalculator-tool/"
	},
	"articles": {
		question: "Need guidance interpreting the dashboard?",
		link: "https://bcome.biz/help-center/tutorials/browse-the-article-dashboard/"
	},
	"data-collection_projects": {
		question: "Need a hand on how to collect data?", //"data-collection-notification",
		link: "https://bcome.biz/help-center/tutorials/how-to-fill-in-the-information-in-the-forms/"
	}
}

export const CATEGORIES_COLORS: Record<string, string> = {
	"Learn": "#B27C2F",
	"Measure": "#65758C",
	"Comply": "#C5B48D",
	"Improve": "#8A978F",
	"Communicate": "#84434C"
}

export const filtersLibrary: string[] = [ "All categories", "My Documents", "Resources" ]

export const EMAIL_INFORMATION = 'anna@bcome.biz'