import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { getTableVisibleColumnsKeys, tableGetColWidth,getFormatedDate } from '../utils/TableUtils';

import '../styles/tableItem.scss';
import Checkbox from 'components/Common/Checkbox/Checkbox';
import { ITableSchema } from '../types/TableTypes';

export interface ITableItemProps {
	schema: ITableSchema,
	entityName: string,
	selectionMode: boolean,
	selectedItems: any[],
	setSelectedItems?: any, // function
	onClick?: any,
	onHover?: any,
	onLeave?: any,
	item: any,
	striped?: boolean,
}

const DefaultItem = (props: any) => {
    const {colW, value, schema, id} = props;

	let breakpoints = `d-flex flex-row`;
	if (schema.columns[id].breakpoints) {
		breakpoints = 'd-none';
		schema.columns[id].breakpoints.forEach((breakpoint: string) => {
			breakpoints += ` d-${breakpoint}-flex `;
		});
	}

	// Si es custom no mostrar el valor y mostrar el componente custom;
	let r = value;
	if (schema.columns[id].component) {
		r = schema.columns[id].component(props);
	}

	// Validación más estricta de valores nulos o indefinidos
	if (r === null || r === undefined || r === '' || r === 'N/A') {
		r = '-';
	}

	const componentClass = `${breakpoints} pt-2 pb-2 text-primary`;
    return (
		<div key={id} className={componentClass} style={{ width: `${colW}%`, fontSize: '14px', lineHeight: '26px'}}>
			{r}
		</div>)
}

const TableItem = (props: ITableItemProps) => {

	const fields = { ...props.item };
	const {schema, selectionMode, selectedItems, setSelectedItems, entityName, onClick, striped, onHover, onLeave} = props;
	const [isSelected, setIsSelected] = useState(false);
	const history = useHistory();

	const { excelDownloadLink } = props.item;

	// Generar cols;
	const cols: any = [];
	const keys = getTableVisibleColumnsKeys(schema);
	keys.forEach((k) => {
		const colW = tableGetColWidth(schema, k);
		let value = fields[k];
		if (schema.columns[k].type == "date") {
			value = getFormatedDate(schema.columns[k], value);
		}
        const html = <DefaultItem {...props} key={k} id={k} value={value} colW={colW} />
		cols.push(html)
	});

	//Checkbox selección
	let selector = <></>
	if (selectionMode) {
		selector = (
			<div className="pr-3">
				<Checkbox checked={isSelected} className="pb-0"/>
			</div>
		)
	}

	useEffect(() => {
		const index = selectedItems.indexOf(props.item);
		setIsSelected(index != -1);
	})

	const onClickRow = () => {
		if (selectionMode) {
			const index = selectedItems.indexOf(props.item);
			if (index == -1) {
				setSelectedItems(selectedItems.concat(props.item));
			}
			else {
				const filteredArray = selectedItems.filter( (val: any) => val !== props.item);
				setSelectedItems(filteredArray);
			}
		}
		else {
			if (onClick) {
				onClick(props.item);
			}
			else { // Default on click
				// Verificar que tengamos un id válido antes de navegar
				const itemId = fields.id;
				if (itemId) {
					console.log('🔄 Navegando a:', { entityName, itemId });
					history.push(`/${entityName}/${itemId}`);
				} else {
					console.warn('⚠️ No se puede navegar: ID no definido', { entityName, fields });
				}
			}
		}
	}

	const onMouseOver = () => {
		if (onHover) {
			onHover(props.item);
		}
	}

	const onMouseLeave = () => {
		if (onLeave) {
			onLeave(props.item);
		}
	}

	const wPath = window.location.pathname;
	const componentClass = `table-item w-100 d-flex flex-row align-items-center px-3 border-bottom cursor-pointer ${striped ? 'striped' : ''}`;
	const componentClassException = `table-item w-100 d-flex flex-row align-items-center border-bottom ${striped ? 'striped' : ''}`;
	const componentClassProjects = `table-item w-100 d-flex flex-row align-items-center cursor-pointer ${striped ? 'striped' : ''}`;
    const handleDownloadExcel = () => {
		window.open(`${excelDownloadLink}`)
	}

	if (wPath === '/projects') {
		return (
			<div className={componentClassException}>
				<div className={componentClassProjects} onClick={onClickRow} onMouseEnter={() => {onMouseOver()}} onMouseLeave={() => {onMouseLeave()}} style={{width:'90%'}} >
					{selector}
					{cols}
				</div>
				<div className="d-flex mr-2">
					<span className="icon-icon-eye cursor-pointer text-dark-100 mr-2 ml-2" style={{ fontSize: '16px' }} onClick={onClickRow}></span>
					<span className="icon-icon-download cursor-pointer text-dark-100 mr-2 ml-2" style={{ fontSize: '16px' }} onClick={handleDownloadExcel}></span>
					<span className="icon-icon-code ml-2 mr-2" style={{ fontSize: '16px', color: '#ACACB2' }}></span>
				</div>
			</div>
		);
	} else {
		return (
			<div className={componentClass} onClick={onClickRow} onMouseEnter={() => {onMouseOver()}} onMouseLeave={() => {onMouseLeave()}}>
				{selector}
				{cols}
			</div>
		);
	}
};

export default TableItem;