import React from 'react';
import './styles.css';

interface FixedNotificationProps {
  type: 'success' | 'warning' | 'error' | 'info';
  text: string;
  linkText?: string;
  linkUrl?: string;
  onClose?: () => void;
  onLinkClick?: () => void;
  position?: 'top-right' | 'bottom-right' | 'bottom-left' | 'top-left';
}

/**
 * Componente de notificación fija que permanece en pantalla
 * Útil para mostrar mensajes de servicio no contratado u otras alertas permanentes
 */
export const FixedNotification: React.FC<FixedNotificationProps> = ({
  type = 'info',
  text,
  linkText,
  linkUrl,
  onClose,
  onLinkClick,
  position = 'bottom-right'
}) => {
  // Determinar el icono y color basado en el tipo
  let icon;
  let colorIcon;

  if (type === 'success') {
    icon = 'icon-success';
    colorIcon = '#9A9753';
  } else if (type === 'warning') {
    icon = 'icon-warning';
    colorIcon = '#E0A75E';
  } else if (type === 'error') {
    icon = 'icon-warning';
    colorIcon = '#B44D49';
  } else if (type === 'info') {
    icon = 'icon-info';
    colorIcon = '#1C1C30';
  }

  // Manejar el clic en el enlace
  const handleLinkClick = (e: React.MouseEvent) => {
    if (onLinkClick) {
      e.preventDefault();
      onLinkClick();
    } else if (!linkUrl) {
      e.preventDefault();
    }
  };

  return (
    <div className={`fixed-notification ${position}`}>
      <div className="fixed-notification-content border-radius-8">
        <div className="d-flex align-items-center">
          {/* <span
            className={`icon-${icon} ml-1 mr-2`}
            style={{ fontSize: '20px', color: colorIcon }}
          ></span> */}
          <span className="d-flex align-items-center font-sfpro-regular font-12px">
            {text}
            {linkText && linkUrl && (
              <>
                {' '}
                <a 
                  href={linkUrl} 
                  className="fixed-notification-link ml-1"
                  onClick={handleLinkClick}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {linkText}
                </a>
              </>
            )}
          </span>
        </div>
        
        {onClose && (
          <button 
            className="fixed-notification-close" 
            onClick={onClose}
            aria-label="Cerrar notificación"
          >
            <span className="icon-icon-close font-8px"></span>
          </button>
        )}
      </div>
    </div>
  );
};

export default FixedNotification;
