import React from 'react';
import { useProjects } from 'contexts/projectsContext';
import RankingNew from './RankingNew';
import { ValidatedRankingProps, ProductType } from 'types/ranking';

const RankingDashboard = () => {
    const { projectSelected } = useProjects();
    const path = window.location.pathname;
    const pathProduct = path?.split('/')[1];

    // Validar que el producto sea del tipo correcto
    const isValidProduct = (value: string): value is ProductType => {
        return ['evaluation', 'metrics'].includes(value);
    };

    const product = isValidProduct(pathProduct) ? pathProduct : undefined;

    if (!projectSelected?.id) {
        return null;
    }

    const rankingProps: ValidatedRankingProps = {
        useNewEndpoint: true,
        projectId: projectSelected.id,
        accountId: projectSelected.accountId,
        product
    };

    return (
        <div className="d-flex flex-row flex-grow-1 overflow-auto">
            <RankingNew {...rankingProps} />
        </div>
    );
};

export default RankingDashboard;
