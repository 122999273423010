import { useRankingData } from 'hooks/useRankingData';
import { RankingCEI } from '../data.interface';

const emptyRanking: RankingCEI = {
    best: [],
    worst: []
};

export const useNewCEIRanking = (projectId: string) => {
    const { data, loading, error } = useRankingData(
        true, // Always use new endpoint
        projectId,
        undefined,
        'Circularity'
    );

    const transformData = (): RankingCEI => {
        if (!data?.Circularity) return emptyRanking;

        return {
            best: data.Circularity.bestArticles || [],
            worst: data.Circularity.worstArticles || []
        };
    };

    return {
        ranking: transformData(),
        loading: loading || !projectId, // consideramos loading si no hay projectId
        error
    };
};
