import React from "react";

export const Hero = ({image, className}: {image: string, className?: string}) => {
    return (
        <div className={`carousel-card--hero ${className || ''}`} style={{ position: 'relative', paddingTop: '53.2%' }}>
            <img 
                src={image} 
                alt="" 
                style={{ 
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                }} 
            />
        </div>
    )
}