import { useProjects } from "contexts/projectsContext";
import { useEffect, useState } from "react";
import Dashboard from "services/http/Dashboards";
import { SummaryCEI } from "../data.interface";

const initSummaryData: SummaryCEI = {
    mci_a: '0',
    mci_a_score: 'low',
    recycled: '0',
    TU_waste: 0,
    utility: '0'
}

const dashboard = new Dashboard();
const fetchData = (id: string): Promise<SummaryCEI> => {
    return dashboard.ceiSummary(id)
        .then((summaryData) => summaryData.data)
        .catch(() => initSummaryData);
}

export const useDashboardData = () => {
    const [loading, setLoading] = useState(true);
    const { projectSelected } = useProjects();
    const [summary, setSummary] = useState<SummaryCEI>(initSummaryData);

    useEffect(() => {
        let isMounted = true;

        if (projectSelected) {
            const id = projectSelected.id;
            setLoading(true);
            fetchData(id)
                .then((summaryData) => {
                    if (isMounted) {
                        setSummary(summaryData);
                    }
                })
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }

        return () => {
            isMounted = false;
        }
    }, [projectSelected]);

    return {
        summary,
        loading,
        projectType: projectSelected?.referenceType
    };
};