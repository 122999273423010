import { useProjects } from "contexts/projectsContext";
import { useReferenceType } from "hooks/useReferenceType.hook";
import React from "react";
import { useTranslation } from 'react-i18next';

interface NAME_ROUTES_INTERFACE {
    [key: string]: string;
}

const ARTICLES = 'Articles';

const NAMES_ROUTES: NAME_ROUTES_INTERFACE = {
    "metrics": "sidebar-nav-metrics",
    "sustainabilityindex": "sidebar-nav-index",
    "sustainabilityindex-dashboard": "common-dashboard",
    "sustainabilityindex-articles": "list",
    "traceability": "sidebar-nav-traceability",
    "lca": "product-selector-lca",
    "lca-dashboard": "common-dashboard",
    "lca-articles": "list",
    "benchmark": "list",
    "benchmark-articles": "common-dashboard",
    "digitization": "sidebar-nav-digitization",
    "ecommerce": " ",
    "ecommerce-integration": "integration-header",
    "ecommerce-customization": "customization-header",
    "smartlabels": "sidebar-nav-smartlabels",
    "smartlabels-articles": "list",
    "plm": "PLM",
    "plm-dashboard": "common-dashboard",
    "plm-articles": "list",
    "plm-suppliers": "list",
    "plm-materials": "list",
    "data-collection": "data-collection",
    "data-collection-projects": "sidebar-nav-projects",
    "cei": "cei",
    "cei-dashboard": "common-dashboard",
    "cei-articles": "list",
    "ecodesign": "sidebar-nav-ecodesign",
    "calculator": "sidebar-nav-ecodesign-calculator",
    "library": "Library",
    "compliance": "Compliance"
}

export const Breadcrumb = () => {
    const { t } = useTranslation();
    const wPath = window.location.pathname;
    const queryParams = new URLSearchParams(window.location.search);
    const generalProduct = wPath.split('/')[1];
    const product = wPath.split('/')[2];
    const scope = wPath.split('/')[3];
    const section = queryParams.get('section');
    const { projectSelected } = useProjects();
    const { validateReferenceType } = useReferenceType(projectSelected);

    return (
        <div className="d-flex flex-row text-dark-100 font-14px" style={{ gap: '8px'}}>
            {
                NAMES_ROUTES[generalProduct] && generalProduct !== 'library' && generalProduct !== 'compliance' &&
                <>
                    <div className="font-14px fw-normal">{ t(NAMES_ROUTES[generalProduct]) }</div>
                    {
                        NAMES_ROUTES[product] && NAMES_ROUTES[product] !== ' ' &&
                        <span className="text-dark-100">{'>'}</span>
                    }
                </>
            }
            {
                generalProduct === 'library' &&
                <>
                    <div className="font-14px fw-normal">Library</div>
                    <span className="text-dark-100">{'>'}</span>
                    {!section && !queryParams.get('filter') && 
                        <div className="font-14px text-decoration-underline">All categories</div>
                    }
                    {section && 
                        <div className="font-14px text-decoration-underline">{section}</div>
                    }
                    {queryParams.get('filter') === 'favorites' && 
                        <div className="font-14px text-decoration-underline fw-normal">{t('Saved Items')}</div>
                    }
                </>
            }
            {
                generalProduct === 'compliance' &&
                <>
                    <div className="font-14px fw-normal">Compliance</div>
                    <span className="text-dark-100">{'>'}</span>
                    {!section && !queryParams.get('filter') && 
                        <div className="font-14px text-decoration-underline">All categories</div>
                    }
                    {section && 
                        <div className="font-14px text-decoration-underline">{section}</div>
                    }
                    {queryParams.get('filter') === 'favorites' && 
                        <div className="font-14px text-decoration-underline fw-normal">{t('Saved Items')}</div>
                    }
                </>
            }
            {
                NAMES_ROUTES[product] && generalProduct !== 'library' && generalProduct !== 'compliance' &&
                <>
                    {
                        NAMES_ROUTES[product] !== ' ' &&
                        <div className={`font-14px ${!NAMES_ROUTES[`${product}-${scope}`] && !queryParams.get('filter') ? 'text-decoration-underline' : ''}`}>
                            { t(NAMES_ROUTES[product]) }
                        </div>
                    }
                    {
                        NAMES_ROUTES[`${product}-${scope}`] &&
                        <span className="text-dark-100">{'>'}</span>
                    }
                </>
            }
            { 
                NAMES_ROUTES[`${product}-${scope}`] && generalProduct !== 'library' && generalProduct !== 'compliance' && 
                <div className={`font-14px ${!queryParams.get('filter') ? 'text-decoration-underline' : ''}`}>
                    { t(NAMES_ROUTES[`${product}-${scope}`]) }
                </div> 
            }
            {
                queryParams.get('filter') === 'favorites' && generalProduct !== 'library' && generalProduct !== 'compliance' && 
                <>
                    <span className="text-dark-100">{'>'}</span>
                    <div className="font-14px text-decoration-underline fw-normal">{t('Saved Items')}</div>
                </>
            }
        </div>
    )
}