import { SimpleTooltip } from "UI/atoms/Tooltips/Index";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { SelectFavorite } from "UI/atoms/SelectFavorite";
import { useHistory } from "react-router-dom";

type LayoutLibraryProps = {
    keyName: string,
    title: string,
    filters: string[],
    filterSelected: string,
    handleFilter: (key: string, value: string) => void,
    orderBy: 'newest' | 'oldest',
    handleOrder: (key: string, value: 'newest' | 'oldest') => void,
    searchText: string,
    handleSearch: (key: string, value: string) => void,
    children: React.ReactNode,
    isFavoriteView?: boolean,
    onFavoriteClick?: () => void
}

type FilterSelectedProps = {
    keyName: string,
    filters: string[],
    filterSelected: string,
    handleFilter: (key: string, value: string) => void,
    isOpen: boolean,
    onToggle: () => void
}

type OrderFilterProps = {
    keyName: string,
    orderBy: 'newest' | 'oldest',
    handleOrder: (key: string, value: 'newest' | 'oldest') => void,
    isOpen: boolean,
    onToggle: () => void
}

export const LayoutLibrary = ({
    keyName,
    title,
    filters,
    filterSelected,
    handleFilter,
    orderBy = 'newest',
    handleOrder,
    searchText,
    handleSearch,
    children,
    isFavoriteView = false,
    onFavoriteClick
}: LayoutLibraryProps) => {
    const [activeDropdown, setActiveDropdown] = useState<'filter' | 'order' | null>(null);
    const history = useHistory();

    const handleFavoriteClick = () => {
        if (onFavoriteClick) {
            onFavoriteClick();
        }
        
        // Mantener el filtro de sección actual si existe
        const params = new URLSearchParams(window.location.search);
        const currentSection = params.get('section');
        
        const newParams = new URLSearchParams();
        newParams.append('filter', 'favorites');
        if (currentSection) {
            newParams.append('section', currentSection);
        }
        
        const queryString = newParams.toString();
        history.push(`/library?${queryString}`);
    };

    return (
        <section className="d-flex flex-column" style={{ height: '97%', overflowY: 'auto', borderRadius: '2px' }}>
            <header className="d-flex flex-row align-items-center justify-content-between w-100 pr-3" style={{ position: 'relative' }}>
                <div className="d-flex flex-row align-items-center gap-4">
                    <div className="d-flex flex-column align-items-start">
                        <FilterSelected 
                            {...{ keyName, filterSelected, filters, handleFilter }} 
                            isOpen={activeDropdown === 'filter'}
                            onToggle={() => setActiveDropdown(activeDropdown === 'filter' ? null : 'filter')}
                        />
                    </div>
                    <div className="d-flex flex-column align-items-start">
                        <OrderFilter 
                            keyName={keyName} 
                            orderBy={orderBy} 
                            handleOrder={handleOrder}
                            isOpen={activeDropdown === 'order'}
                            onToggle={() => setActiveDropdown(activeDropdown === 'order' ? null : 'order')}
                        />
                    </div>
                </div>
                <div className="d-flex flex-row align-items-center">
                    <h3 className="font-20px text-dark-100 m-0 pr-5 font-sfpro-regular">{title}</h3>
                    <div className="d-flex align-items-center">
                        <Search keyName={keyName} searchText={searchText} handleSearch={handleSearch} />
                        <SelectFavorite select={isFavoriteView} onClick={handleFavoriteClick} />
                    </div>
                </div>
            </header>
            <div className="flex-grow-1">
                {children}
            </div>
        </section>
    )
}

const FilterSelected = ({ keyName, filters, filterSelected, handleFilter, isOpen, onToggle }: FilterSelectedProps) => {
    const [hoverFilter, setHoverFilter] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleHoverFilter = (isHover: boolean) => () => setHoverFilter(isHover);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) && isOpen) {
                onToggle();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onToggle]);

    return (
        <div
            ref={dropdownRef}
            className="d-flex flex-column align-items-start"
            style={{ position: 'relative', zIndex: 1000 }}
        >
            <div
                className="position-relative d-flex flex-row align-items-center nav-link cursor-pointer px-0 py-0 pl-3 pr-1"
                onMouseEnter={handleHoverFilter(true)}
                onMouseLeave={handleHoverFilter(false)}
                onClick={onToggle}
            >
                <span className={`text-dark-100 font-14px font-sfpro-regular text-overflow-ellipsis overflow-hidden`}>
                    {filterSelected}
                </span>
                <div className={`d-flex align-items-center justify-content-center`}
                    style={{
                        height: "32px",
                        width: "32px",
                    }}>
                    <span
                        className={`icon-icon-chevron-down font-30px`}
                        style={{
                            transition: "all 0.5s ease",
                            transformOrigin: "50% 50%",
                            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                        }}
                    />
                </div>
                {
                    isOpen &&
                    <FilterDropdown keyName={keyName} filters={filters} filterSelected={filterSelected} handleFilter={handleFilter} />
                }
            </div>
        </div>
    )
}

const FilterDropdown = ({ keyName, filters, filterSelected, handleFilter }:
    { keyName: string, filters: string[], filterSelected: string, handleFilter: (keyName: string, name: string) => void }) => {
    const validateEqual = (filter: string) => filterSelected === filter;
    return (
        <div
            className="dropdown-menu show position-absolute"
            style={{ left: "auto", width: "280px", zIndex: 1001 }}
        >
            {filters.map((filter: string) => (
                <button
                    key={`filter-${filter}`}
                    onClick={() => handleFilter(keyName, filter)}
                    className={
                        validateEqual(filter)
                            ? "selected-project font-sfpro-medium d-flex align-items-center dropdown-item"
                            : "dropdown-item"
                    }
                >
                    {validateEqual(filter) && (
                        <span className="icon-icon-tick mr-2 text-dark-100 font-18px"></span>
                    )}
                    <div
                        className={`${validateEqual(filter)
                                ? "font-sfpro-medium"
                                : "font-sfpro-regular"
                            }
                        text-overflow-ellipsis overflow-hidden`}
                    >
                        {filter}
                    </div>
                </button>
            ))}
        </div>
    );
}

const OrderFilter = ({ keyName, orderBy, handleOrder, isOpen, onToggle }: OrderFilterProps) => {
    const { t } = useTranslation();
    const validateEqual = (order: string) => orderBy === order;
    const dropdownRef = useRef<HTMLDivElement>(null);

    const capitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) && isOpen) {
                onToggle();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onToggle]);

    return (
        <div
            ref={dropdownRef}
            className="d-flex flex-column align-items-start"
            style={{ position: 'relative', zIndex: 1000 }}
        >
            <div 
                className="position-relative d-flex flex-row align-items-center nav-link cursor-pointer px-0 py-0 pl-3 pr-1"
                onClick={onToggle}
            >
                <span className="text-dark-100 font-14px font-sfpro-regular text-overflow-ellipsis overflow-hidden">
                    Sort: {capitalizeFirstLetter(orderBy)}
                </span>
                <div className="d-flex align-items-center justify-content-center">
                    <span
                        className="icon-icon-chevron-down font-30px"
                        style={{
                            transition: "all 0.5s ease",
                            transformOrigin: "50% 50%",
                            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                        }}
                    />
                </div>
            </div>
            {isOpen && (
                <div
                    className="dropdown-menu show position-absolute"
                    style={{ left: "auto", width: "280px", zIndex: 1001 }}
                >
                    {['newest', 'oldest'].map((order) => (
                        <button
                            key={order}
                            onClick={() => {
                                handleOrder(keyName, order as 'newest' | 'oldest');
                                onToggle();
                            }}
                            className={
                                validateEqual(order)
                                    ? "selected-project font-sfpro-medium d-flex align-items-center dropdown-item"
                                    : "dropdown-item"
                            }
                        >
                            {validateEqual(order) && (
                                <span className="icon-icon-tick mr-2 text-dark-100 font-18px"></span>
                            )}
                            <div
                                className={`${validateEqual(order)
                                        ? "font-sfpro-medium"
                                        : "font-sfpro-regular"
                                    }
                                text-overflow-ellipsis overflow-hidden`}
                            >
                                {capitalizeFirstLetter(order)}
                            </div>
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

const Search = ({ searchText, handleSearch, keyName }: any) => {
    const { t } = useTranslation();
    const [isShow, setIsShow] = useState<boolean>(false);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const restartSearch = () => {
        handleSearch(keyName, '');
    };

    const searchClick = () => {
        setIsShow(!isShow);
        restartSearch();
    };

    const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleSearch(keyName, e.target.value);
    };

    return (
        <div className={
            isShow
                ? 'search-bar-active d-flex flex-row-reverse align-items-center'
                : 'search-bar d-flex flex-row-reverse align-items-center'
        }>
            <div
                id={`search-bar-${keyName}`}
                onMouseEnter={() => setIsTooltipOpen(true)}
                onMouseLeave={() => setIsTooltipOpen(false)}
                onClick={searchClick}
                className="d-flex align-items-center text-dark-60 text-dark-100-hover"
            >
                <span
                    className={`icon-icon-${isShow ? 'close font-1px' : 'search font-20px'
                        }`}
                    style={{ margin: '6px' }}
                ></span>
            </div>

            {isShow && (
                <input
                    className="search-input font-sfpro-regular"
                    type="text"
                    placeholder={`Search ${keyName}...`}
                    value={searchText}
                    onChange={handleSelect}
                />
            )}

            <SimpleTooltip
                tooltipId={`search-bar-${keyName}`}
                tooltipOpen={isTooltipOpen}
                text={t(`${keyName}-finder`)}
            />
        </div>
    )
}