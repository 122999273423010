import Crud, { ICrudListResponse } from "./Crud";
import axios, { AxiosError } from "axios";

export default class Library extends Crud {
    constructor() {
        super("library");
    }

    async getAll(): Promise<ICrudListResponse> {
        return await this.get('/?sort=createdAt,DESC');
    }

    async getComplianceDocuments(accountId: string): Promise<ICrudListResponse> {
        try {            
            const complianceUrl = `/compliance/${accountId}`;
            
            const response = await this.get(complianceUrl);
            
            return {
                data: response.data,
                count: Array.isArray(response.data) ? response.data.length : (response.data ? 1 : 0)
            };
        } catch (error) {
            console.error('Error in getComplianceDocuments:', error);
            
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                console.error('Error details:', {
                    message: axiosError.message,
                    status: axiosError.response?.status,
                    statusText: axiosError.response?.statusText,
                    data: axiosError.response?.data
                });
            } else {
                console.error('Non-Axios error:', error);
            }
            
            throw error;
        }
    }
}
