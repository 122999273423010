import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Points from 'UI/atoms/Points/Index';
import ArticleDetail from 'UI/molecules/ArticleDetail';
import { getPoints } from 'utils/IndexQualifications';
import { formatNumber, formatNumberNoDecimals } from 'utils/utils';
import { SimpleTooltip } from 'UI/atoms/Tooltips/Index';
import { ValidatedRankingProps, ProductType, RankingMetricKey } from 'types/ranking';
import { useRankingData } from 'hooks/useRankingData';
import './Row.scss';
import { Table } from './Ranking';

const ARTICLE_HIGH_SCORE = 'high';
const ARTICLE_MEDIUM_SCORE = 'medium';
const ARTICLE_LOW_SCORE = 'low';
const TOTAL_COLUMNS = 12;
const TRACEABILITY = 'traceability';
const BEST_ARTICLES = 'bestArticles';

type MetricGroup = (RankingMetricKey['key'])[];

interface RankingOrderType {
    evaluation: {
        group1: MetricGroup;
        group2: MetricGroup;
    };
    metrics: {
        group1: MetricGroup;
        group2: MetricGroup;
    };
}

interface SectionWrapProps {
    sectionGroup: MetricGroup;
    useNewEndpoint?: boolean;
    projectId?: string;
    accountId?: string;
    data?: any;
    allowedGroups?: string[];
}

const RankingNew = (props: ValidatedRankingProps) => {
    const { data: propsData, product, useNewEndpoint, projectId, accountId } = props;
    const { t } = useTranslation();

    // Definir los grupos de métricas
    const rankingOrder: RankingOrderType = {
        evaluation: {
            group1: ['Planet', 'People'],
            group2: ['Transparency', 'Circularity'],
        },
        metrics: {
            group1: [
                'Savings_Water_Relative',
                'Savings_ClimateChange_Relative',
            ],
            group2: [
                'Savings_Eutrophication_Relative',
                'Savings_FossilFuels_Relative',
            ],
        },
    };

    // Si no hay datos y no estamos usando el nuevo endpoint, no renderizar
    if (!propsData && !useNewEndpoint) {
        return null;
    }

    // Si no hay producto definido, no renderizar
    if (!product) {
        console.warn('No se ha especificado un producto válido');
        return null;
    }

    // Asegurarse de que solo se muestren los grupos permitidos
    const getAllowedGroups = (productType: ProductType): MetricGroup => {
        if (!(productType in rankingOrder)) {
            console.warn(`Tipo de producto ${productType} no válido`);
            return [];
        }
        return [
            ...rankingOrder[productType].group1,
            ...rankingOrder[productType].group2
        ];
    };

    const allowedGroups = getAllowedGroups(product);

    return (
        <div className="row">
            <div className="col-12">
                <div className="d-flex w-100 flex-wrap justify-content-around">
                    <SectionWrap
                        sectionGroup={rankingOrder[product].group1}
                        useNewEndpoint={useNewEndpoint}
                        projectId={projectId}
                        accountId={accountId}
                        data={propsData}
                        allowedGroups={allowedGroups}
                    />
                    <SectionWrap
                        sectionGroup={rankingOrder[product].group2}
                        useNewEndpoint={useNewEndpoint}
                        projectId={projectId}
                        accountId={accountId}
                        data={propsData}
                        allowedGroups={allowedGroups}
                    />
                </div>
            </div>
        </div>
    );
};

const SectionWrap = ({ sectionGroup, useNewEndpoint, projectId, accountId, data: propsData, allowedGroups }: SectionWrapProps) => {
    if (!Array.isArray(sectionGroup)) {
        return null;
    }

    return (
        <div className="d-flex col flex-wrap px-0">
            {sectionGroup.map((group) => {
                if (!allowedGroups?.includes(group)) {
                    return null;
                }

                const { data: newData, loading, error } = useRankingData(
                    useNewEndpoint ?? false,
                    projectId,
                    accountId,
                    group
                );

                return (
                    <Section
                        key={group}
                        group={group}
                        data={useNewEndpoint ? newData?.[group] : propsData?.[group]}
                    />
                );
            })}
        </div>
    );
};

interface SectionProps {
    group: string;
    data: any;
}

const Section = (props: SectionProps) => {
    const { group, data } = props;

    if (!data) {
        return null;
    }

    const bestArticles = data.bestArticles || [];
    const worstArticles = data.worstArticles || [];

    return (
        <div
            className="mx-2 col"
            style={{ minWidth: '275px', maxWidth: '600px' }}
        >
            <HeaderSection sectionName={group} />
            <HeaderTable group={group} bestArticles />
            <div>
                <Table
                    group={group}
                    articleGroupName="bestArticles"
                    articleGroup={bestArticles}
                />
            </div>
            <div className="mt-4 mb-4">
                <HeaderTable group={group} worstArticles />
                <Table
                    group={group}
                    articleGroupName="worstArticles"
                    articleGroup={worstArticles}
                />
            </div>
        </div>
    );
};

const HeaderSection = ({ sectionName }: { sectionName: string }) => {
    const { t } = useTranslation()
    return (
        <>
            <section className="row pb-3">
                <div className="col-8 font-14px text-black-100">
                    {t(`ranking-title-${sectionName}`)}
                </div>
            </section>
        </>
    );
};

export const HeaderTable = ({ group, bestArticles, worstArticles, traceability }: any) => {
    const { t } = useTranslation()
    const indexHeaders = [
        'Planet',
        'People',
        'Circularity',
        'Transparency',
    ];

    return (
        <div className="row pb-3">
            <div className="col-8 font-14px text-blue-metal-30 text-overflow-ellipsis text-nowrap overflow-hidden">
                {bestArticles && t('dash-ranking-best-articles')}
                {worstArticles && t('dash-ranking-worst-articles')}
            </div>
            <div className="font-14px text-blue-metal-30 text-overflow-ellipsis text-nowrap overflow-hidden">
                {
                    traceability
                        ? t('common-total-score')
                        : indexHeaders.includes(group)
                            ? t('qualification')
                            : t('percentage')}
            </div>
        </div>
    );
};

// Exportar los componentes existentes sin cambios
export { Table } from './Ranking';

// Exportar el nuevo componente como default
export default RankingNew;
