import React, { useState, useEffect } from "react";
import { Hero } from "UI/atoms/Carousel/Hero";
import { Subtitle } from "UI/atoms/Carousel/Subtitle";
import { Tag } from "UI/atoms/Carousel/Tag";
import { Title } from "UI/atoms/Carousel/Title";
import { LibraryResource } from "loader/library-loader";
import { CardSkeleton } from "UI/atoms/Skeleton/CardSkeleton";
import { SelectFavorite } from "UI/atoms/SelectFavorite";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { ExternalLink } from "../../atoms/ExternalLink";

// Evento personalizado para cambios en favoritos
const FAVORITES_CHANGED_EVENT = 'favoritesChanged';

type CardGridProps = {
    data: LibraryResource[]
}

export const CardGrid = ({ data }: CardGridProps) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [loadingItems, setLoadingItems] = useState<{ [key: string]: boolean }>({});
    const [favorites, setFavorites] = useState<{ [key: string]: boolean }>({});
    const [imageStatus, setImageStatus] = useState<{ [key: string]: 'loading' | 'error' | 'loaded' }>({});
    const isInFavoritesView = window.location.search.includes('filter=favorites');

    console.log(data)

    useEffect(() => {
        // Cargar favoritos del localStorage
        const savedFavorites = localStorage.getItem('libraryFavorites');
        if (savedFavorites) {
            const favoritesData = JSON.parse(savedFavorites);
            const favoriteState: { [key: string]: boolean } = {};

            // Convertir la estructura a un objeto de estado booleano
            Object.keys(favoritesData).forEach(key => {
                favoriteState[key] = true;
            });

            setFavorites(favoriteState);
        }
    }, []);

    useEffect(() => {
        // Reset image status when data changes
        setImageStatus({});

        // Verificar cada URL de imagen
        data.forEach(item => {
            if (!item.cover) {
                setImageStatus(prev => ({ ...prev, [item.url]: 'error' }));
                return;
            }

            const img = new Image();
            img.onload = () => {
                setImageStatus(prev => ({ ...prev, [item.url]: 'loaded' }));
            };
            img.onerror = () => {
                setImageStatus(prev => ({ ...prev, [item.url]: 'error' }));
            };
            setImageStatus(prev => ({ ...prev, [item.url]: 'loading' }));
            img.src = item.cover;
        });
    }, [data]);

    const handleFavorite = (e: React.MouseEvent, item: LibraryResource) => {
        e.preventDefault();
        e.stopPropagation();

        // Si el item no tiene URL, usar el ID como clave
        const key = item.url || item.id;

        const savedFavorites = localStorage.getItem('libraryFavorites');
        const favorites = savedFavorites ? JSON.parse(savedFavorites) : {};

        if (favorites[key]) {
            // Si ya existe, lo removemos
            delete favorites[key];
        } else {
            // Si no existe, lo agregamos
            favorites[key] = {
                id: item.id,
                title: item.title,
                url: item.url,
                cover: item.cover,
                section: item.section,
                category: item.category,
                createdAt: item.createdAt
            };
        }

        localStorage.setItem('libraryFavorites', JSON.stringify(favorites));

        setFavorites(prev => ({
            ...prev,
            [key]: !prev[key]
        }));

        // Disparar evento personalizado
        window.dispatchEvent(new CustomEvent(FAVORITES_CHANGED_EVENT, {
            detail: { key, isFavorite: !favorites[key] }
        }));
    };

    const formatDate = (date: Date | string): string => {
        const dateObj = date instanceof Date ? date : new Date(date);
        return dateObj.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    }

    const isNew = (date: Date | string): boolean => {
        const createdDate = new Date(date);
        const now = new Date();
        const monthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
        return now.getTime() - createdDate.getTime() <= monthInMilliseconds;
    }

    const getFileType = (url: string): string => {
        if (!url) return '';

        const lowercaseUrl = url.toLowerCase();

        // Verificar dominios específicos primero
        try {
            const urlObj = new URL(url);
            const hostname = urlObj.hostname.toLowerCase();

            // Plataformas de audio/podcast
            if (hostname.includes('spotify.com') ||
                hostname.includes('soundcloud.com') ||
                hostname.includes('anchor.fm')) {
                return 'Podcast';
            }

            // Plataformas de video
            if (hostname.includes('youtube.com') ||
                hostname.includes('youtu.be') ||
                hostname.includes('vimeo.com')) {
                return 'Video';
            }

            // Google Sheets
            if (hostname === 'docs.google.com' && urlObj.pathname.toLowerCase().includes('/spreadsheets/d/')) {
                return 'Excel';
            }

            // Google Drive
            if (hostname.includes('drive.google.com')) {
                // Detectar PDF en Drive
                if (urlObj.pathname.toLowerCase().includes('/file/d/')) {
                    return 'PDF';
                }
                const pathType = urlObj.pathname.split('/')[3]?.toLowerCase();
                if (pathType === 'document') return 'Word';
                if (pathType === 'presentation') return 'PowerPoint';
            }
        } catch (error) {
            // Si la URL no es válida, continuamos con la verificación de extensiones
        }

        // Verificar extensiones de archivo
        const extensions = {
            pdf: /\.pdf($|\?|#)/i,
            excel: /\.(xlsx|xls|csv)($|\?|#)/i,
            word: /\.(docx|doc)($|\?|#)/i,
            powerpoint: /\.(pptx|ppt)($|\?|#)/i,
            video: /\.(mp4|webm|mov|avi)($|\?|#)/i,
            audio: /\.(mp3|wav|ogg)($|\?|#)/i
        };

        for (const [type, regex] of Object.entries(extensions)) {
            if (regex.test(lowercaseUrl)) {
                switch (type) {
                    case 'pdf': return 'PDF';
                    case 'excel': return 'Excel';
                    case 'word': return 'Word';
                    case 'powerpoint': return 'PowerPoint';
                    case 'video': return 'Video';
                    case 'audio': return 'Podcast';
                }
            }
        }

        return '';
    }

    const getSectionName = (section: string): string => {
        return section === 'Documents' ? 'My Documents' : 'Resources';
    }

    const isAudiovisualContent = (url: string) => {
        if (!url) return false;
        // Dominios comunes de contenido audiovisual
        const audiovisualDomains = [
            'youtube.com', 'youtu.be',
            'vimeo.com',
            'spotify.com',
            'soundcloud.com',
            'dailymotion.com',
            'ted.com',
            'twitch.tv'
        ];
        try {
            const urlObj = new URL(url);
            return audiovisualDomains.some(domain => urlObj.hostname.includes(domain));
        } catch {
            return false;
        }
    };

    const isDownloadableFile = (url: string) => {
        if (!url) return false;
        
        // Verificar extensiones de archivo comunes
        const fileExtensions = ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.txt', '.csv', '.zip', '.rar'];
        if (fileExtensions.some(ext => url.toLowerCase().endsWith(ext))) {
            return true;
        }
        
        // Verificar si es un archivo de Google Drive
        try {
            const urlObj = new URL(url);
            const hostname = urlObj.hostname.toLowerCase();
            
            // Google Sheets
            if (hostname === 'docs.google.com' && urlObj.pathname.toLowerCase().includes('/spreadsheets/d/')) {
                return true;
            }
            
            // Google Drive Files
            if (hostname.includes('drive.google.com')) {
                // Detectar archivos en Drive
                if (urlObj.pathname.toLowerCase().includes('/file/d/')) {
                    return true;
                }
                
                // Detectar documentos específicos
                const pathType = urlObj.pathname.split('/')[3]?.toLowerCase();
                if (['document', 'presentation', 'spreadsheets'].includes(pathType)) {
                    return true;
                }
            }
        } catch (error) {
            console.error('Error al analizar URL para descarga:', error);
        }
        
        return false;
    };

    const isExternalLink = (url: string): boolean => {
        return !isDownloadableFile(url);
    }

    const handleDownload = async (e: React.MouseEvent, url: string, index: number) => {
        e.preventDefault();
        e.stopPropagation();

        // Set loading state for this specific item
        setLoadingItems(prev => ({ ...prev, [index]: true }));

        try {
            let downloadUrl = url;
            let fileId = null;

            // Detectar si es un enlace de Google Drive
            if (url.includes('drive.google.com')) {
                fileId = url.match(/\/(?:file|spreadsheets|document|presentation)\/d\/([^/]+)/)?.[1];
                if (fileId) {
                    downloadUrl = `https://drive.google.com/uc?export=download&id=${fileId}`;
                } else {
                    throw new Error('Google Drive URL no válida');
                }
            }
            // Detectar si es un enlace de Google Sheets
            else if (url.includes('docs.google.com/spreadsheets')) {
                fileId = url.match(/\/d\/([^/]+)/)?.[1];
                if (fileId) {
                    // Para hojas de cálculo, usar formato de exportación específico
                    downloadUrl = `https://docs.google.com/spreadsheets/d/${fileId}/export?format=xlsx`;
                } else {
                    throw new Error('Google Sheets URL no válida');
                }
            }

            // Función para crear y usar un iframe oculto para descargas
            const downloadWithIframe = (url: string) => {
                // Eliminar iframe anterior si existe
                const existingIframe = document.getElementById('hidden-download-iframe');
                if (existingIframe) {
                    document.body.removeChild(existingIframe);
                }
                
                // Crear un iframe oculto
                const iframe = document.createElement('iframe');
                iframe.id = 'hidden-download-iframe';
                iframe.style.display = 'none';
                document.body.appendChild(iframe);
                
                // Configurar el iframe para descargar el archivo
                if (iframe.contentWindow) {
                    iframe.contentWindow.location.href = url;
                }
                
                // Limpiar después de un tiempo
                setTimeout(() => {
                    if (document.body.contains(iframe)) {
                        document.body.removeChild(iframe);
                    }
                }, 5000);
                
                return true;
            };

            // Para archivos de Google Drive y Sheets, usar iframe oculto
            if (fileId) {
                const success = downloadWithIframe(downloadUrl);
                
                if (!success) {
                    // Fallback al método anterior si el iframe no funciona
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', '');
                    link.setAttribute('target', '_blank');
                    link.style.display = 'none';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                
                // Simular un retraso para que el loader se desactive después del clic
                await new Promise(resolve => setTimeout(resolve, 1000));
            } else {
                // Descargar el archivo con fetch para otros casos (como HubSpot)
                try {
                    const response = await fetch(downloadUrl);
                    if (!response.ok) {
                        throw new Error('Error al descargar el archivo');
                    }
                    const blob = await response.blob();

                    // Crear un enlace temporal para la descarga
                    const tempUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = tempUrl;

                    // Extraer el nombre del archivo o usar uno genérico
                    const fileName = decodeURIComponent(
                        url.split('/').pop()?.split('?')[0] || 'download'
                    );
                    link.download = fileName;
                    link.style.display = 'none';

                    // Simular clic y limpiar
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(tempUrl);
                } catch (fetchError) {
                    console.error('Error al descargar con fetch:', fetchError);
                    // Si falla el fetch, intentar con iframe
                    downloadWithIframe(downloadUrl);
                }
            }
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
            // Fallback: abrir en nueva pestaña si la descarga falla
            window.open(url, '_blank');
        } finally {
            // Reset loading state for this specific item
            setLoadingItems(prev => ({ ...prev, [index]: false }));
        }
    };

    return (
        <div className="grid-container pl-2" >
            {isInFavoritesView && data.length === 0 ? (
                <div className="empty-favorites">
                    <p>You currently have no saved items.</p>
                    <button onClick={() => history.push('/library')}>
                        Back to all items
                    </button>
                </div>
            ) : (
                data.map((item: LibraryResource, index: number) => (
                    <a
                        key={`card-${index}`}
                        href={item.url}
                        target="__blank"
                        rel="noopener"
                        className="text-decoration-none"
                        style={{ height: 'auto', display: 'block' }}
                    >
                        <Card id={`card-${index}`}>
                            <Card.Title text={item.title} />
                            <Card.Subtitle
                                text={formatDate(item.createdAt)}
                                tag={null}
                            />
                            <div className="card-hero">
                                {(!item.cover || imageStatus[item.url] === 'loading') ? (
                                    <CardSkeleton />
                                ) : (
                                    <Card.Hero image={item.cover} className="card-hero " />
                                )}
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-3">
                                <div className="d-flex align-items-center" style={{ gap: '8px' }}>
                                    {isNew(item.createdAt) && (
                                        <Tag text="New" bgColor="#f0f0f2" />
                                    )}
                                    <Tag text={getSectionName(item.section)} bgColor="#f0f0f2" />
                                    {getFileType(item.url) && (
                                        <Tag text={getFileType(item.url)} bgColor="#f0f0f2" />
                                    )}
                                </div>
                                {/* //// */}
                                <div className="d-flex align-items-center">
                                    {isAudiovisualContent(item.url) && !isDownloadableFile(item.url) && (
                                        <ExternalLink
                                            href={item.url}
                                        />
                                    )}
                                    {isDownloadableFile(item.url) && (
                                        <div
                                            onClick={(e) => handleDownload(e, item.url, index)}
                                            style={{
                                                width: '24px',
                                                height: '24px',
                                                padding: '3px 5px 5px 3px',
                                                cursor: 'pointer',
                                                borderRadius: '2px',
                                                transition: 'background-color 0.2s ease',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: 'transparent',
                                                marginLeft: isAudiovisualContent(item.url) && !isDownloadableFile(item.url) ? '8px' : '0'
                                            }}
                                            className="download-icon"
                                        >
                                            {loadingItems[index] ? (
                                                <div className="spinner" style={{
                                                    width: '16px',
                                                    height: '16px',
                                                    border: '2px solid #706F7A',
                                                    borderTop: '2px solid transparent',
                                                    borderRadius: '50%',
                                                    animation: 'spin 1s linear infinite'
                                                }} />
                                            ) : (
                                                <span
                                                    className="icon-icon-download font-20px"
                                                    style={{
                                                        width: '16px',
                                                        height: '16px'
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )}
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginLeft: '8px'
                                        }}
                                    >
                                        <SelectFavorite
                                            select={favorites[item.url || item.id]}
                                            onClick={(e) => handleFavorite(e, item)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </a>
                ))
            )}
            <style>
                {`
                    @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                    .grid-container {
                        display: grid;
                        grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
                        gap: 24px;
                        width: 100%;
                        justify-content: start;
                        position: relative;
                        min-height: ${isInFavoritesView && data.length === 0 ? 'calc(100vh - 200px)' : 'auto'};
                    }
                    @media (max-width: 1200px) {
                        .grid-container {
                            grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
                        }
                    }
                    .empty-favorites {
                        width: 320px;
                        padding: 24px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 16px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                    .empty-favorites p {
                        margin: 0;
                        color: var(--New-Palette-Dark-100, #1C1C30);
                        text-align: center;
                    }
                    .empty-favorites button {
                        display: flex;
                        padding: 12px 32px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        align-self: stretch;
                        border-radius: 4px;
                        background: var(--New-Palette-Dark-100, #1C1C30);
                        color: white;
                        border: none;
                        cursor: pointer;
                        transition: opacity 0.2s ease;
                    }
                    .card-item {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        height: auto;
                        min-height: 350px;
                        box-sizing: border-box;
                    }
                    .text-decoration-none {
                        display: block;
                        width: 100%;
                        height: auto;
                    }
                    .card-hero {
                        overflow: hidden;
                        aspect-ratio: 16/9;
                        width: 100%;
                        flex-shrink: 0;
                    }
                    .card-hero img {
                        transition: transform 0.3s ease;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    .card-hero:hover img {
                        transform: scale(1.05);
                    }
                    .download-icon:hover,
                    .link-icon:hover {
                        background-color: #F0F0F2;
                    }
                    svg {
                        color: inherit;
                    }
                `}
            </style>
        </div>
    );
}

const Card = ({ id, children }: { id: string, children: any }) => {
    return (
        <div id={id} className="card-item" style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            minHeight: '350px',
            boxSizing: 'border-box'
        }}>
            {children}
        </div>
    );
}

Card.Title = Title;
Card.Subtitle = Subtitle;
Card.Hero = Hero;

export default CardGrid;
