import React, { useEffect, useRef, useState } from "react";

import { useProjects } from "contexts/projectsContext";
import { ResTable, useTable } from "contexts/tableContext";
import FilterHttp from 'services/http/Filters';
import { ICrudListResponse } from "services/http/Crud";
import Project from "services/http/Project";

import { InformationNavigation } from "UI/molecules/InformationNavigation";
import { EMetricNames } from "UI/Template/Table/types/TableEnums";
import { EMAIL_INFORMATION } from "../constants";
import { LocalLoader } from "components/Common/Loader/Loader";

export type ScopeType = 'materials' | 'suppliers' | 'articles' | 'cei' | 'projects';

// Componente adaptador para el Loader que cumple con la interfaz esperada según la memoria
const TableLoader: React.FC<{ show: boolean, fullScreen?: boolean }> = ({ show, fullScreen }) => {
    if (!show) return null;
    return <LocalLoader />;
};

const validateDataFields = [EMetricNames.MCI_A, EMetricNames.Recycled_A, EMetricNames.TU_Waste_A, EMetricNames.Utility_A];

const Filters = new FilterHttp();
const Projects = new Project();

function fetchData (scope: ScopeType, projectId: string, dataQuery: any): Promise<ICrudListResponse> {
    if (scope === 'materials') return Filters.filterMaterials(projectId, dataQuery);
    if (scope === 'suppliers') return Filters.filterSuppliers(projectId, dataQuery);
    if (scope === 'articles') return Filters.filterArticles(projectId, dataQuery);
    if (scope === 'projects') return Projects.allProjects('&sort=createdAt,DESC');
    if (scope === 'cei') return Filters.filterCei(projectId, dataQuery);

    return Promise.reject('Invalid scope');
}

function defineScope (path: string): ScopeType {
    if (path.includes('/plm/materials')) return 'materials';
    if (path.includes('/plm/suppliers')) return 'suppliers';
    if (path.includes('/data-collection')) return 'projects';
    if (path.includes('/cei/')) return 'cei';
    return 'articles';

}

export const tableLoader = (Component: any) => {
    return (props: any) => {
        const scope = useRef<ScopeType>('articles');
        const source = Filters.getSource();
        const mountedRef = useRef(true);
        const { projectSelected, setShowHeader } = useProjects();
        const [noData, setNoData] = useState(false);
        const {
            currentPage,
            limitPagination,
            searchText,
            sortMetric,
            filtersApplied,
            selectedView,
            isLoading,
            handleLoading,
            setRes,
            res,
            setInfiniteScrollLoading,
            infiniteScrollLoading,
            setCurrentPage
        } = useTable();

        const safeSetState = (callback: () => void) => {
            if (mountedRef.current) {
                callback();
            }
        };

        const getInitData = (scope: ScopeType) => {
            if (!mountedRef.current || !projectSelected) return;

            const dataQuery = {
                currentPage: currentPage,
                limitPagination: limitPagination,
                search: searchText,
                sortMetric: sortMetric,
                filters: filtersApplied
            }

            safeSetState(() => {
                handleLoading(true);
                setRes({ datas: [], pageCount: 0, total: 0 });
            });

            const request = fetchData(scope, projectSelected.id, dataQuery);

            request.then((response) => {
                if (!mountedRef.current) return;

                let data: ResTable['datas'] | null = null;
                if (scope === 'cei') {
                    data = response.data['articles'];
                } else if (scope === 'projects') {
                    data = response.data;
                } else {
                    data = response.data[scope];
                }

                // Validación más estricta de los datos y asegurar que cada item tenga un id
                const validData = Array.isArray(data) ? 
                    data.filter(item => {
                        if (!item || typeof item !== 'object') return false;
                        // Verificar que el item tenga un id válido
                        return item.id !== undefined && item.id !== null;
                    }) : [];

                safeSetState(() => {
                    handleLoading(false);
                    setNoData(validData.length === 0);
                    setRes({
                        datas: validData,
                        pageCount: response.data.pageCount || 0,
                        total: response.data.total || 0,
                    });
                    setShowHeader(true);
                });
            }).catch((error) => {
                safeSetState(() => {
                    handleLoading(false);
                    setNoData(true);
                    setRes({ datas: [], pageCount: 0, total: 0 });
                });
            });
        };

        const getData = (scope: ScopeType, isInitialLoad = false) => {
            if (!mountedRef.current || !projectSelected) return;

            const dataQuery = {
                currentPage: currentPage,
                limitPagination: limitPagination,
                search: searchText,
                sortMetric: sortMetric,
                filters: filtersApplied
            }

            if (isInitialLoad) {
                // Para cargas iniciales, siempre mostrar el loader principal y limpiar datos
                safeSetState(() => {
                    handleLoading(true);
                    setInfiniteScrollLoading(false);
                    setRes({ datas: [], pageCount: 0, total: 0 });
                });
            } else if (isInfiniteScrollModeRef.current) {
                // Para scroll infinito, mostrar solo el loader de scroll
                safeSetState(() => {
                    handleLoading(false);
                    setInfiniteScrollLoading(true);
                });
            } else {
                // Para otras cargas (como cambio de página sin scroll infinito)
                safeSetState(() => {
                    handleLoading(true);
                    setInfiniteScrollLoading(false);
                });
            }

            const request = fetchData(scope, projectSelected.id, dataQuery);

            request.then((response) => {
                if (!mountedRef.current) return;

                let data: ResTable['datas'] | null = null;
                if (scope === 'cei') {
                    data = response.data['articles'];
                } else if (scope === 'projects') {
                    data = response.data;
                } else {
                    data = response.data[scope];
                }

                // Validación más estricta de los datos y asegurar que cada item tenga un id
                const validData = Array.isArray(data) ? 
                    data.filter(item => {
                        if (!item || typeof item !== 'object') return false;
                        // Verificar que el item tenga un id válido
                        return item.id !== undefined && item.id !== null;
                    }) : [];

                safeSetState(() => {
                    // Siempre ocultar los loaders
                    handleLoading(false);
                    setInfiniteScrollLoading(false);
                    
                    if (isInitialLoad) {
                        // Para cargas iniciales, reemplazar completamente los datos
                        setRes({
                            datas: validData,
                            pageCount: response.data.pageCount || 0,
                            total: response.data.total || 0,
                        });
                    } else if (isInfiniteScrollModeRef.current) {
                        // Para scroll infinito, acumular datos
                        // Importante: preservar el orden de las páginas
                        setRes((prevState: ResTable) => {
                            // Asegurarse de que no haya duplicados basados en id
                            const existingIds = new Set(prevState.datas.map(item => item.id));
                            const newItems = validData.filter(item => !existingIds.has(item.id));
                            
                            return {
                                datas: [...prevState.datas, ...newItems],
                                pageCount: response.data.pageCount || prevState.pageCount,
                                total: response.data.total || prevState.total,
                            };
                        });
                    } else {
                        // Para otras cargas (como cambio de página sin scroll infinito)
                        setRes({
                            datas: validData,
                            pageCount: response.data.pageCount || 0,
                            total: response.data.total || 0,
                        });
                    }
                    
                    setNoData(validData.length === 0);
                    setShowHeader(true);
                });
            }).catch((error) => {
                safeSetState(() => {
                    handleLoading(false);
                    setInfiniteScrollLoading(false);
                    setNoData(true);
                });
            });
        };

        const lastFiltersRef = useRef(filtersApplied);
        const lastSearchRef = useRef(searchText);
        const lastSortRef = useRef(sortMetric);

        const lastScopeRef = useRef<ScopeType | null>(null);

        // Referencia para el último proyecto seleccionado
        const lastProjectRef = useRef<string | null>(null);
        // Referencia para rastrear si estamos en modo de scroll infinito
        const isInfiniteScrollModeRef = useRef<boolean>(false);
        // Referencia para mantener un registro de las páginas ya cargadas
        const loadedPagesRef = useRef<Set<number>>(new Set([1]));

        useEffect(() => {
            if (!projectSelected) return;

            const wPath = window.location.pathname;
            const currentScope = defineScope(wPath);
            
            // Verificar si cambió el proyecto
            const projectChanged = lastProjectRef.current !== projectSelected.id;
            if (projectChanged) {
                // Actualizar la referencia del último proyecto
                lastProjectRef.current = projectSelected.id;
                
                // Reiniciar el modo de scroll infinito y las páginas cargadas
                isInfiniteScrollModeRef.current = false;
                loadedPagesRef.current = new Set([1]);
                
                // Mostrar el loader y limpiar los datos anteriores
                safeSetState(() => {
                    handleLoading(true);
                    setInfiniteScrollLoading(false);
                    setRes({ datas: [], pageCount: 0, total: 0 });
                    // Reiniciar a la primera página cuando cambia el proyecto
                    setCurrentPage(1);
                });
                
                // Obtener nuevos datos para el proyecto seleccionado
                getData(currentScope, true);
                return;
            }
            
            // Solo actualizar el scope si realmente cambió el path
            const scopeChanged = lastScopeRef.current !== null && lastScopeRef.current !== currentScope;
            if (scopeChanged) {
                lastScopeRef.current = currentScope;
                scope.current = currentScope;
                
                // Reiniciar el modo de scroll infinito y las páginas cargadas
                isInfiniteScrollModeRef.current = false;
                loadedPagesRef.current = new Set([1]);
                
                getData(currentScope, true);
                return;
            } else if (lastScopeRef.current === null) {
                // Primera inicialización del scope
                lastScopeRef.current = currentScope;
                scope.current = currentScope;
            }
            
            const filtersChanged = Object.values(filtersApplied).some((value: unknown) => {
                if (Array.isArray(value)) {
                    return value.length > 0;
                }
                return false;
            });

            const searchChanged = lastSearchRef.current !== searchText;
            const sortChanged = JSON.stringify(lastSortRef.current) !== JSON.stringify(sortMetric);

            if (filtersChanged || searchChanged || sortChanged) {
                // Reiniciar el modo de scroll infinito y las páginas cargadas
                isInfiniteScrollModeRef.current = false;
                loadedPagesRef.current = new Set([1]);
                
                lastFiltersRef.current = filtersApplied;
                lastSearchRef.current = searchText;
                lastSortRef.current = sortMetric;
                
                // Si cambiaron los filtros, búsqueda o ordenamiento, siempre es carga inicial
                getData(currentScope, true);
                return;
            }

            // Actualizar referencias
            lastFiltersRef.current = filtersApplied;
            lastSearchRef.current = searchText;
            lastSortRef.current = sortMetric;

            // Verificar si la página actual ya ha sido cargada
            const pageAlreadyLoaded = loadedPagesRef.current.has(currentPage);
            
            // Determinar si es carga inicial - solo es inicial si estamos en página 1 y no hay datos
            const isInitialLoad = currentPage === 1 && res.datas.length === 0;
            
            // Si la página es mayor que 1 y no ha sido cargada, estamos en modo scroll infinito
            if (currentPage > 1 && !pageAlreadyLoaded) {
                isInfiniteScrollModeRef.current = true;
                // Registrar esta página como cargada
                loadedPagesRef.current.add(currentPage);
            }
            
            // Si la página ya ha sido cargada, no hacemos nada
            if (pageAlreadyLoaded && !isInitialLoad) {
                return;
            }

            // Si estamos en modo scroll infinito, asegurarnos de que no se trate como carga inicial
            const shouldUseInitialLoad = isInitialLoad && currentPage === 1;
            
            // Obtener datos para la página actual
            getData(currentScope, shouldUseInitialLoad);

            return () => source.cancel();
        }, [filtersApplied, currentPage, limitPagination, sortMetric, selectedView, searchText, projectSelected]);

        useEffect(() => {
            const hasActiveFilters = Object.values(filtersApplied).some((value: unknown) => {
                if (Array.isArray(value)) {
                    return value.length > 0;
                }
                return false;
            });

            if (hasActiveFilters && currentPage !== 1) {
                setCurrentPage(1); 
            }
        }, [filtersApplied]);

        useEffect(() => {
            return () => {
                mountedRef.current = false;
                source.cancel();
            };
        }, []);

        const validateData = () => {
            if (scope.current === 'cei') {
                let count = 0;
                validateDataFields.forEach(item => {
                    if (res.datas[0] && (res.datas[0][item] === null || res.datas[0][item] === 0)) {
                        count++;
                    }
                });
                
                setShowHeader(true);
                return count === validateDataFields.length;
            }
            return false;
        };

        return validateData()
            ? <InformationNavigation keyName={scope.current} />
            : (
                <>
                    {/* Mostrar el loader solo cuando está cargando */}
                    <TableLoader show={isLoading} />
                    
                    {/* Componente de tabla con sus datos */}
                    <Component 
                        {...props} 
                        items={res.datas} 
                        isLoading={isLoading} 
                        infiniteScrollLoading={isInfiniteScrollModeRef.current && res.datas.length > 0}
                    />
                </>
            )
    }
}