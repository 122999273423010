import React, { useState } from "react";

interface Props {
    href: string;
    className?: string;
}

export const ExternalLink = ({ href, className }: Props) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            title="Open link"
            className={className}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="20" 
                height="20" 
                viewBox="0 0 18 17" 
                fill="none"
            >
                <path 
                    d="M7.82142 3.78607L9.58918 2.0183C9.9761 1.63139 10.4354 1.32447 10.9409 1.11508C11.4465 0.905683 11.9883 0.79791 12.5355 0.79791C13.0826 0.79791 13.6245 0.905684 14.13 1.11508C14.6355 1.32447 15.0948 1.63139 15.4817 2.0183C15.8687 2.40521 16.1756 2.86454 16.385 3.37006C16.5944 3.87558 16.7021 4.4174 16.7021 4.96458C16.7021 5.51175 16.5944 6.05357 16.385 6.55909C16.1756 7.06461 15.8687 7.52394 15.4817 7.91085L13.714 9.67862M10.1784 13.2142L8.41067 14.9819C8.02376 15.3688 7.56443 15.6757 7.05891 15.8851C6.55339 16.0945 6.01157 16.2023 5.46439 16.2023C4.35933 16.2023 3.29952 15.7633 2.51812 14.9819C1.73671 14.2005 1.29773 13.1407 1.29773 12.0356C1.29773 10.9306 1.73672 9.87077 2.51812 9.08937L4.28588 7.3216M6.64291 10.8571L11.357 6.14309" 
                    stroke={isHovered ? '#706F7A' : '#1C1C30'} 
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                />
            </svg>
        </a>
    );
};
