import React from 'react';

export const CardSkeleton = () => {
    return (
        <div className="skeleton-image">
            <style>
                {`
                    @keyframes shimmer {
                        0% {
                            transform: translateY(-100%);
                            opacity: 0.4;
                        }
                        50% {
                            opacity: 0.6;
                        }
                        100% {
                            transform: translateY(100%);
                            opacity: 0.4;
                        }
                    }

                    .skeleton-image {
                        width: 100%;
                        position: relative;
                        padding-top: 53.2%;
                        overflow: hidden;
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.03) 100%), 
                                    linear-gradient(180deg, rgba(0, 0, 0, 0.00) 50.5%, rgba(0, 0, 0, 0.20) 100%);
                        border-radius: 2px;
                    }

                    .skeleton-image::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.1) 100%);
                        animation: shimmer 1.8s infinite ease-in-out;
                        filter: blur(4px);
                    }
                `}
            </style>
        </div>
    );
};
